import React from "react";
import { useStoreContext } from "../context/store";

export default function FileInput({ inputRef, onChange, label, multiple = false }) {
  const { uploadInfo } = useStoreContext();

  return (
    <div className="file-input">
      <label htmlFor="uploadButton" className="button small">
        {label}
      </label>
      <input 
        ref={inputRef} 
        onChange={onChange} 
        id="uploadButton" 
        type="file" 
        multiple={multiple} 
        {...(uploadInfo.supportedFormats && uploadInfo.supportedFormats.length > 0 ? {accept: uploadInfo.supportedFormats.join(',')} : {})} 
        />
    </div>
  );
}
