import apisauce from "apisauce";
//import { setupCache } from 'axios-cache-adapter';

/*const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});*/

export const create = (baseURL = process.env.REACT_APP_URL_SERVICES) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
    },
    timeout: 120000,
    //adapter: cache.adapter,
  });

  const completion = (mascotId, newPrompt) => {
    return api.post(`/mascot/${mascotId}/completion`, newPrompt);
  };

  const dataUpload = async (mascotId, file, userId) => {
    const uploadUrlResult = await api.get(`/mascot/${mascotId}/uploadUrl`, { type: file.type });
    if (!uploadUrlResult.ok) {
      throw new Error(uploadUrlResult.originalError);
    }
    const headers = {
      "Content-Type": file.type,
    };
    const uploadResult = await api.put(uploadUrlResult.data.uploadUrl, file, { headers });
    if (!uploadResult.ok) {
      throw new Error(uploadResult.originalError);
    }
    return api.put(`/mascot/${mascotId}/upload`, {
      userId,
      uploadId: uploadUrlResult.data.uploadId,
      filename: file.name
    });
  };

  const dataGet = async (mascotId, uploadId, data = {}) => {
    const uploadUrlResult = await api.get(`/mascot/${mascotId}/upload/${uploadId}`, data);
    if (!uploadUrlResult.ok) {
      throw new Error(uploadUrlResult.originalError);
    }
    return api.get(uploadUrlResult.data.uploadUrl);
  };

  const dataUpdate = (mascotId, uploadId, data) => {
    return api.post(`/mascot/${mascotId}/upload/${uploadId}`, data);
  };

  const dataDelete = (mascotId, uploadId, data = {}) => {
    return api.delete(`/mascot/${mascotId}/upload/${uploadId}`, data);
  };

  const taskDelete = (mascotId, taskId, data = {}) => {
    return api.delete(`/mascot/${mascotId}/task/${taskId}`, data);
  };

  const taskStop = (mascotId, taskId, data = {}) => {
    return api.post(`/mascot/${mascotId}/task/${taskId}/stop`, data);
  };

  const embeddingGenerate = (mascotId, data = {}) => {
    return api.post(`/mascot/${mascotId}/generateEmbedding`, data);
  };

  const addUrl = (mascotId, url, userId) => {
    return api.put(`/mascot/${mascotId}/addUrl`, { url, userId });
  };

  const refreshUrl = (mascotId, uploadId, userId) => {
    return api.put(`/mascot/${mascotId}/url/${uploadId}/refresh`, { userId });
  };

  const initSessionTitle = (mascotId, sessionId, msg) => {
    return api.put(`/mascot/${mascotId}/initSessionTitle/${sessionId}`, { msg });
  };

  const doneTraining = (mascotId, sessionId, data={}) => {
    return api.post(`/mascot/${mascotId}/doneTraining/${sessionId}`, data);
  };

  const uploadInfo = () => {
    return api.get(`/upload-info`);
  };

  const runSessionstatsAnalysisBySession = (sessionId) => {
    return api.get(`/run-sessionstats-analysis/${sessionId}`);
  };

  return {
    completion,
    dataUpload,
    dataGet,
    dataUpdate,
    dataDelete,
    taskDelete,
    taskStop,
    embeddingGenerate,
    addUrl,
    refreshUrl,
    initSessionTitle,
    doneTraining,
    uploadInfo,
    runSessionstatsAnalysisBySession,
    api,
  };
};

export const servicesClient = create();
