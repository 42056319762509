import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

// Context
import { useAuth } from "../hooks/useAuth";
import { useStoreContext } from "../context/store";

// Components
import MascotImage from "./MascotImage";

export default function MascotSidebar(props) {
  const { isAuthenticated } = useAuth();
  const { currentMascot, ownMascot } = useStoreContext();
  const editing = props.editing;
  const sidebarOpen = props.sidebarOpen;
  const setSidebarOpen = props.setSidebarOpen;

  return (
    <div className={classNames("mascot-sidebar", !currentMascot.image && "bg-empty", sidebarOpen && "open")}>
      {/* Close button for mobile */}
      <div className="icon-btn close-sidebar" onClick={() => setSidebarOpen(!sidebarOpen)}>
        <i className="icon-close"></i>
      </div>
      <div className="mascot-sidebar-content">
        {!editing && isAuthenticated && ownMascot && !props.loading && (
          <Link to="/" className="to-home">
            <i className="icon-chevron-left"></i>
          </Link>
        )}
        {!editing && isAuthenticated && ownMascot && props.loading && (
          <div className="to-home disabled">
            <i className="icon-chevron-left"></i>
          </div>
        )}
        <div className="mascot-header">
          <div className="mascot-image-wrapper">
            <MascotImage mascot={currentMascot}></MascotImage>
          </div>

          {currentMascot.name && (
            <div className="mascot-heading">
              <h2>{currentMascot.name}</h2>
              <p>{currentMascot.description}</p>
            </div>
          )}

          <NavLink to={`/mascot/${currentMascot._id}`} className="edit-nav-item">
            <button className="default">Back to Mascot</button>
          </NavLink>
        </div>

        <nav className="edit-nav">
          <hr className="dark" />

          <NavLink to={`/mascot/${currentMascot._id}/insights/overview`} className="edit-nav-item">
            <span>Overview</span>
          </NavLink>

          <NavLink to={`/mascot/${currentMascot._id}/insights/sessions`} className="edit-nav-item">
            <span>Session Analysis</span>
          </NavLink>

          <NavLink to={`/mascot/${currentMascot._id}/insights/interactions`} className="edit-nav-item">
            <span>Interaction Metrics</span>
          </NavLink>
        </nav>
      </div>

      <div className="mascot-sidebar-bg-wrapper">
        <div 
          className="mascot-sidebar-bg"
          style={currentMascot.image && { backgroundImage: `url(${currentMascot.image})` }}
        ></div>
      </div>
    </div>
  );
}
