import React, { useEffect, useState } from "react";
import { client } from "../services/api";
import { Link } from "react-router-dom";

// Context
import { setCurrentMascot, useStoreContext } from "../context/store";
import { useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

// Components
import MascotSidebarInsights from "../components/MascotSidebarInsights";
import HeaderInsights from "../components/HeaderInsights";
import Modal from "../components/Modal";
import TableChart from "../components/charts/TableChart";
import DoughnutChart from "../components/charts/DoughnutChart";
import SingleValueChart from "../components/charts/SingleValueChart";

export default function MascotInsightsSessions() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { intervalTime, setIntervalTime } = useAuth();
  const { mascots } = useStoreContext();
  const { mascotId } = useParams();

  const [dataSuccessRating, setDataSuccessRating] = useState();
  const [valueSuccessRating, setValueSuccessRating] = useState("");
  const [dataSessionDuration, setDataSessionDuration] = useState();
  const [dataTopTopics, setDataTopTopics] = useState();
  const [dataUnkownAnswer, setDataUnkownAnswer] = useState(0);

  const [showModalUnknownAnswer, setShowModalUnknownAnswer] = useState();

  useEffect(() => {
    setCurrentMascot(mascotId);
  }, [mascots, mascotId]);

  useEffect(() => {
    const fetchData = async () => {
      client.getStatsSessionsDurationByMascot(mascotId, intervalTime).then((res) => {
        if (res.status === 200) setDataSessionDuration(res.data.duration);
      });
      client.getStatsSessionsUnknownAnswersByMascot(mascotId, intervalTime).then((res) => {
        if (res.ok) {
          setDataUnkownAnswer(res.data);
        }
      });
      client.getStatsSessionsSuccessRatingByMascot(mascotId, intervalTime).then((res) => {
        if (res.ok && res.data.length > 0) {
          const sc = Math.round(res.data[0].successRating * 10);
          setValueSuccessRating(`${parseInt(sc)}%`);
          setDataSuccessRating([
            { _id: "satisfacted", count: sc, perc: sc, label: "Satisfied", color: "#10E6BB" },
            { _id: "unsatisfacted", count: 100 - sc, perc: 100 - sc, label: "Unsatisfied", hide: true },
          ]);
        } else {
          setDataSuccessRating([]);
          setValueSuccessRating("");
        }
      });
      client.getMascotTopTopics(mascotId).then((res) => {
        if (res.ok) {
          setDataTopTopics(res.data.topTopics);
        }
      });
    };

    fetchData();
    // eslint-disable-next-line
  }, [intervalTime]);

  return (
    <div className="mascot-wrapper">
      <MascotSidebarInsights editing sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}></MascotSidebarInsights>

      <div className="mascot-insights-wrapper">
        <HeaderInsights intervalTime={intervalTime} onSetIntervalTime={setIntervalTime}></HeaderInsights>

        <div className="charts-wrapper col-2">
          <DoughnutChart
            title={"Success Rating"}
            className={"col-2"}
            data={dataSuccessRating}
            value={valueSuccessRating}
            valueLabel="Satisfaction"
            label={"Average session satisfaction"}
          ></DoughnutChart>
          <SingleValueChart
            className={"col-2"}
            title={"Session Duration"}
            label={"Average duration per session"}
            unit={"Minutes"}
            value={dataSessionDuration}
            color="#13C8FF"
          ></SingleValueChart>
        </div>

        <div className="charts-wrapper col-2">
          <TableChart
            title={"Top 10 topics"}
            className={"col-2"}
            showIndex={true}
            hideCount={true}
            data={
              dataTopTopics &&
              dataTopTopics.map((t) => {
                return {
                  _id: t,
                };
              })
            }
          ></TableChart>
          <SingleValueChart
            className={"col-2"}
            title={"Unknown Answers"}
            label="# of times the mascot admitted to not knowing the answer"
            value={dataUnkownAnswer.length}
            action={() => setShowModalUnknownAnswer(true)}
            color="#E61040"
          ></SingleValueChart>
        </div>

        {dataUnkownAnswer && (
          <Modal
            title={
              dataUnkownAnswer.length === 1
                ? dataUnkownAnswer.length + " Unknown Answer"
                : dataUnkownAnswer.length + " Unknown Answers"
            }
            size="large"
            isOpen={showModalUnknownAnswer}
            close={() => setShowModalUnknownAnswer(false)}
          >
            {dataUnkownAnswer.map((answer, i) => (
              <div className="blockquote-pair" key={i}>
                <blockquote className="prompt-preview">
                  <span className="meta small">Prompt</span>
                  <p>{answer.prompt?.trim()}</p>

                  <Link
                    to={
                      "/mascot/" + mascotId + "/session/" + answer.session + "?selectedMessage=" + new Date(answer.date).getTime()
                    }
                  >
                    Go To Session
                  </Link>
                </blockquote>
                <blockquote className="response-preview">
                  <span className="meta small">Response</span>
                  <p>{answer.response?.trim()}</p>
                </blockquote>
              </div>
            ))}
          </Modal>
        )}
      </div>
    </div>
  );
}
