import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

import BgImg from "../images/sign-in-bg.png";
import Logo from "../images/logo-wiseox.svg";

const schema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email Address is required"),
});

export function ForgotPassword() {
  const [pending, setPending] = useState(false);
  const [complete, setComplete] = useState(false);
  const { submitting, sendPasswordResetEmail } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (!submitting && pending) {
      setPending(false);
      setComplete(true);
      reset({ email: "" });
    }
  }, [submitting, pending, reset]);

  const onSubmit = (data) => {
    setPending(true);
    sendPasswordResetEmail(data.email);
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div className="auth-wrapper" style={{backgroundImage: `url(${BgImg})`}}>
      <div className="logo-wrapper">
        <img className="logo" src={Logo} alt="WiseOx Logo" />

        <NavLink to="/login">
          <i className="icon-chevron-left"></i> Sign In
        </NavLink>
      </div>

      <div className="signup-form">
        {!complete ? (
          <>
            <h1>Forgot Password?</h1>
            <p className="large">Please enter the email address for your account, and we will send you a link to reset your password.</p>

            <div className="input-group">
              <input
                {...register("email", { required: true })}
                type="text"
                required
                name="email"
                placeholder="Email Address"
                tabIndex="1"
                onKeyDown={_handleKeyDown}
              />
              {errors.email && <div className="error">{errors.email.message}</div>}
            </div>

            <button className="positive large" tabIndex="2" disabled={!!submitting} onClick={handleSubmit(onSubmit)}>
              Send Link
            </button>
          </>
        ) : (
          <>
            <h1>Link has been sent</h1>
            <p className="large">Please check your email to reset your password.</p>
            <NavLink className="button" to="/login">
              Back to Sign In
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
}
