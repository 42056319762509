import React, { useState, useEffect, useCallback, useRef } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

// Libs
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";
import { useHistory, useParams } from "react-router-dom";
import { client } from "../services/api";
import { servicesClient } from "../services/servicesApi";
import MDEditor from "@uiw/react-md-editor";
import { subscribe, unsubscribe } from "../services/events";

// Context
import { useSocket } from "../context/ws";
import { useAuth } from "../hooks/useAuth";
import {
  useStoreContext,
  setCurrentMascot,
  addSession,
  updateSessionTitle,
  updateSessionLastPrompt,
  selectLastSession,
  updateSessionToken,
  removeSession,
  updateSessions,
  selectSession,
  addSessions,
} from "../context/store";

// Components
import MascotSidebar from "../components/MascotSidebar";
import MascotImage from "../components/MascotImage";
import MascotExcerpts from "../components/MascotExcerpts";
import TrainingModeSidebar from "../components/TrainingModeSidebar";
import SelectTextPopup from "../components/SelectTextPopup";
import Modal from "../components/Modal";
import Popover from "../components/Popover.js";
import Loader from "../components/Loader";

// Images
import HourGlass from "../images/hourglass.svg";
import MessageChat from "../components/MessageChat.js";

export default function MascotChat(props) {
  const { mascotId, sessionId } = useParams();
  const { currentMascot, selectedSession, mascots, ownMascot, mascotsLoading, currentOrganization } = useStoreContext();
  const { currentUser } = useAuth();
  const { isAuthenticated, clientId } = useAuth();
  const history = useHistory();
  const [newMessage, setNewMessage] = useState("");
  const [loadingConversation, setLoadingConversation] = useState(true);
  const [initConversation, setInitConversation] = useState(false);
  const [editingTitle, setEditingTitle] = useState();
  const [session, setSession] = useState({});
  const [messages, setMessages] = useState([]);
  const [runningAnswer, setRunningAnswer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [streamingMessage, setStreamingMessage] = useState({});
  const [pendingAnswer, setPendingAnswer] = useState({});
  const [initSessionStats, setInitSessionStats] = useState({});
  const [showExcerpts, setShowExcerpts] = useState(false);
  const [unavailable, setUnavailable] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [newMessageFocus, setNewMessageFocus] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { socket } = useSocket();
  const [trainingMode, setTrainingMode] = useState(false);
  const [showedPopup, setShowedPopup] = useState([]);
  const [isShowModalFeedback, setShowModalFeedback] = useState();
  const [addedFeedback, setAddedFeedback] = useState(false);
  const [feedbackValue, setFeedbackValue] = useState("");
  const [feedbackValueInit, setFeedbackValueInit] = useState("");
  const [errorFeedback, setErrorFeedback] = useState();
  const [showOptionsPopover, setShowOptionsPopover] = useState(false);
  const classPopover = ".message.assistant .message-content";
  const chatEndEl = useRef();
  const chatWrapperEl = useRef();
  const textareaEl = useRef();
  const [showSessionStatsModal, setShowSessionStatsModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [selectedDataFormat, setSelectedDataFormat] = useState("json");
  const [aggregatedStatsSessions, setAggregatedStatsSessions] = useState({});
  const [newMessageAnimation, setNewMessageAnimation] = useState(false);
  const [refreshingSessionStats, setRefreshingSessionStats] = useState(false);
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(true);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const selectedMessage = searchParams.get("selectedMessage");

  useEffect(() => {
    if (ownMascot && selectedSession && selectedSession._id && selectedSession._id !== "new") {
      fetchStatsSessionsBySession(selectedSession._id);
    }
  }, [selectedSession, ownMascot]);

  const fetchStatsSessionsBySession = async (sessionId) => {
    return await client.getStatsSessionsBySession(sessionId).then((res) => res.ok && setAggregatedStatsSessions(res.data));
  };

  useEffect(() => {
    if (!mascotsLoading || !currentUser) {
      if (mascots.map((m) => m._id).includes(mascotId)) {
        setCurrentMascot(mascotId);
        checkReady(mascots.find((m) => m._id === mascotId));
      } else {
        client.getMascot(mascotId).then((result) => {
          if (!result.ok || result.data?.archived) {
            setUnavailable(result.data?.message);
          }
          if (result.ok) {
            setCurrentMascot(undefined, result.data);
            checkReady(result.data);
          }
        });
      }
    }
  }, [mascots, history, mascotId, mascotsLoading, currentUser]);

  const checkReady = (currentMascot) => {
    if (!currentMascot || !currentMascot._id) {
      return;
    }
    if (currentMascot.dataType === "complexSpreadsheets") {
      let ready = false;
      for (let u of currentMascot.data.uploads) {
        if (u.mascot_data_type_at_upload === "complexSpreadsheets") {
          ready = true;
        }
      }
      setIsReady(ready);
    } else {
      setIsReady(!!!currentMascot.data.notReady);
    }
  };

  useEffect(() => {
    selectLastSession();
    if (!checkEmbedded()) {
      textareaEl.current?.focus();
    }
  }, []);

  useEffect(() => {
    subscribe("changeSession", changeSession);
    return () => {
      unsubscribe("changeSession", changeSession);
    };
    // eslint-disable-next-line
  }, [currentUser, currentMascot]);

  useEffect(() => {
    // Send ready message to parent Iframe
    if (window.parent && currentMascot) {
      window.parent.postMessage(
        {
          type: "wiseox-mascot-ready",
          mascot: currentMascot,
        },
        "*"
      );
    }

    // eslint-disable-next-line
  }, [currentMascot]);

  const changeSession = (data) => {
    getPreviousMessages(data.detail.sessionId);
  };

  useEffect(() => {
    window.addEventListener("message", handlePostMessage);
    return () => window.removeEventListener("message", handlePostMessage);
    // eslint-disable-next-line
  }, [socket, session, messages, loading]);

  const handlePostMessage = useCallback(
    async (e) => {
      if (e.data.type === "send-message" && !loading) {
        handleSubmitWs(e.data.message);
      }
    },
    // eslint-disable-next-line
    [socket, session, messages, loading]
  );

  useEffect(() => {
    if (!selectedSession) return;

    setEditingTitle(false);
    if (selectedSession._id !== session._id || messages.length === 0) {
      let session = selectedSession || {};
      setSession(session);
    } else {
      setLoadingConversation(false);
    }
    // eslint-disable-next-line
  }, [selectedSession]);

  useEffect(() => {
    if (currentMascot._id && selectedSession && selectedSession._id && loadingConversation && !initConversation) {
      setInitConversation(true);
      getPreviousMessages(selectedSession._id);
    }
    // eslint-disable-next-line
  }, [selectedSession, currentMascot]);

  const initClickListenerElement = useCallback((el) => {
    el?.addEventListener("mousedown", (e) => {
      e.target.closest(classPopover).classList.add("not-hoverable");
    });
    el?.addEventListener("mouseup", openMenu);
  }, []);

  const getPreviousMessages = useCallback(
    async (sessionId) => {
      setLoadingConversation(true);
      let messages = [];

      if (!currentMascot._id) return;

      if (sessionId && sessionId !== "new") {
        let sessionDetail = await client.getSession(sessionId);
        if (sessionDetail.ok) {
          for (let d in sessionDetail.data?.data) {
            let data = sessionDetail.data.data[d];
            let userName;
            if (data.prompt.slack) {
              userName = "Slack User";
            } else if (data.prompt.userId && sessionDetail.data.names && data.prompt.userId in sessionDetail.data.names) {
              userName = sessionDetail.data.names[data.prompt.userId];
            } else {
              userName = "Guest";
            }
            messages.push({
              role: "user",
              content: data.prompt.text,
              timestamp: data.prompt.timestamp,
              sessionId: sessionId,
              userId: currentUser ? currentUser._id : null,
              userName,
              messageIndex: parseInt(d),
            });
            messages.push({
              role: "assistant",
              content: data.response.text,
              timestamp: data.response.timestamp,
              sessionId: sessionId,
              userId: currentUser ? currentUser._id : null,
              source_documents: data.response.source_documents,
              feedback: data.response.feedback,
              correctAnswer: data.response.correctAnswer,
              messageIndex: parseInt(d),
            });
          }
        }
      } else if (currentMascot.prompt_settings && currentMascot.prompt_settings.reverse_prompt) {
        messages.push({
          role: "assistant",
          content: currentMascot.prompt_settings.reverse_prompt_text,
          sessionId: sessionId,
          userId: currentUser ? currentUser._id : null,
          source_documents: [],
          timestamp: new Date(),
        });
      }

      if (pendingAnswer[sessionId]) {
        messages.push({
          role: "user",
          content: pendingAnswer[sessionId],
          sessionId: sessionId,
          userId: currentUser ? currentUser._id : null,
          userName: currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : "Guest",
          source_documents: [],
          timestamp: new Date(),
        });
      }

      setMessages(messages);
      //TODO: find a better solution instead of setTimeouts
      setTimeout(() => {
        attachLinkStats(sessionId);
      }, 200);
      setLoadingConversation(false);

      setTimeout(() => {
        if (currentMascot.prompt_settings.inline_actions) {
          document.querySelectorAll(classPopover).forEach(initClickListenerElement);
        }
      }, 200);
    },
    // eslint-disable-next-line
    [currentUser, currentMascot, initClickListenerElement]
  );

  useEffect(() => {
    if (!socket) return;
    function messageSent(data) {
      setRunningAnswer(data["answerId"]);
      if (data["sessionId"] !== session._id) {
        return;
      }
    }

    function onCompletion(data) {
      if ("error" in data) {
        const partial_answer = streamingMessage[data.sessionId];
        pendingAnswer[data.sessionId] = "";
        setPendingAnswer(pendingAnswer);
        streamingMessage[data.sessionId] = "";
        setStreamingMessage(streamingMessage);
        setLoading(false);
        setRunningAnswer(null);
        if (data.error !== "Stopped") {
          setMessages((messages) => [
            ...messages,
            {
              role: "system",
              content: `Error: ${data.value}`,
            },
          ]);
        } else {
          setMessages((messages) => [
            ...messages,
            {
              role: "assistant",
              content: partial_answer,
            },
          ]);
        }
      } else if ("next_word" in data) {
        if (!streamingMessage[data.sessionId]) streamingMessage[data.sessionId] = "";
        streamingMessage[data.sessionId] += data.next_word;
        setStreamingMessage({ ...streamingMessage });
      } else {
        streamingMessage[data.sessionId] = "";
        setRunningAnswer(null);
        setStreamingMessage(streamingMessage);
        pendingAnswer[data.sessionId] = "";
        setPendingAnswer(pendingAnswer);
        let newResponse = data.message;
        newResponse.messageIndex = data.messageIndex;

        if (selectedSession?._id === data.sessionId) {
          setMessages((messages) => [...messages, newResponse]);
        }

        setLoading(false);

        setTimeout(() => {
          let nodes = document.querySelectorAll(classPopover);
          let last = nodes[nodes.length - 1];
          initClickListenerElement(last);
        }, 200);

        chatWrapperEl.current?.scrollTo({
          top: chatEndEl.current?.offsetTop,
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });

        // Send message to parent Iframe
        if (window.parent) {
          window.parent.postMessage(
            {
              type: "received-message",
              message: newResponse.content,
              mascotId: currentMascot._id,
              sessionId: data.sessionId,
            },
            "*"
          );
        }

        // Check if links shown
        const regexMdLinks = /\[([^[]+)\](\(.*\))/gm;
        const matches = newResponse.content.match(regexMdLinks);

        if ((matches && matches.length > 0) || newResponse.content.indexOf("</a>") !== -1) {
          if (initSessionStats[data.sessionId]) {
            client.updateSessionStatsCountLinksShown(initSessionStats[data.sessionId]);
            attachLinkStats(data.sessionId, true);
          }
        }
      }
    }

    socket.on("completion", onCompletion);
    socket.on("messageSent", messageSent);
    return () => {
      socket.off("completion", onCompletion);
      socket.off("messageSent", messageSent);
    };
    // eslint-disable-next-line
  }, [socket, currentMascot, session, messages, selectedSession]);

  const refreshSessionStats = async () => {
    if (!selectedSession) return;
    setRefreshingSessionStats(true);
    let runReq = await servicesClient.runSessionstatsAnalysisBySession(selectedSession._id);
    if (runReq.ok) {
      await fetchStatsSessionsBySession(selectedSession._id);
    }
    setRefreshingSessionStats(false);
  };

  const attachLinkStats = (sessionId, onlyToLast) => {
    let selector = ".wmde-markdown a";
    if (onlyToLast) {
      selector = ".wmde-markdown:last-of-type a";
    }

    const countLinkClicked = () => {
      if (sessionId !== "new") {
        if (!initSessionStats[sessionId]) {
          client.createSessionStats(sessionId, clientId).then((res) => {
            if (res.ok) {
              initSessionStats[sessionId] = res.data?._id;
              setInitSessionStats(initSessionStats);
              client.updateSessionStatsCountLinksClicked(res.data._id);
            }
          });
        } else {
          client.updateSessionStatsCountLinksClicked(initSessionStats[sessionId]);
        }
      }
    };

    document.querySelectorAll(selector).forEach((e) => {
      e.removeEventListener("click", countLinkClicked);
      e.addEventListener("click", countLinkClicked);
    });
  };

  const handleStopStream = useCallback(async () => {
    socket.emit("stop", { answer_id: runningAnswer });
    setRunningAnswer(null);
    setLoading(false);
  }, [socket, runningAnswer]);

  const handleSubmitWs = useCallback(
    async (topicPrompt, isTopicClick) => {
      // Set prop class for animation
      setNewMessageAnimation(true);
      // Remove prop class after animation has finished
      setTimeout(() => {
        setNewMessageAnimation(false);
      }, 300);

      window.parent.postMessage(
        {
          type: "sending-message",
          message: topicPrompt,
          mascotId: currentMascot._id,
          session: session._id,
        },
        "*"
      );

      if (!socket) return;
      setLoading(true);
      topicPrompt = topicPrompt ? topicPrompt : newMessage;
      let sessionId = session._id;

      let newPrompt = [
        ...messages,
        {
          role: "user",
          content: topicPrompt,
          sessionId: sessionId,
          userId: currentUser ? currentUser._id : null,
          userName: currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : "Guest",
          clientId,
        },
      ];
      setMessages((messages) => newPrompt);
      setNewMessage("");

      setTimeout(() => {
        chatWrapperEl.current?.scrollTo({
          top: chatEndEl.current?.offsetTop,
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }, 100);

      if (sessionId == null || sessionId === "new") {
        try {
          const newSession = {
            user: currentUser ? currentUser._id : null,
            clientId,
            mascot: mascotId,
            data: [],
            created: new Date(),
            title: session.title || new moment(session.created).format("MM-DD-YYYY hh:mm"),
          };
          const res = await client.createSession(newSession);
          if (!res.ok) {
            throw res.data;
          }
          sessionId = res.data._id;
          pendingAnswer[sessionId] = topicPrompt;
          setPendingAnswer(pendingAnswer);
          let sessionData = res.data;
          sessionData.created = new Date();
          sessionData.lastPrompt = new Date().toISOString();
          setSession(sessionData);

          if (!session.title || session.title === "New session") {
            const resTitle = await servicesClient.initSessionTitle(mascotId, sessionId, topicPrompt);

            if (resTitle.ok) {
              sessionData = resTitle.data;
            }
          }

          sessionData.created = new Date();
          sessionData.lastPrompt = new Date().toISOString();
          addSession(sessionData);
          setSession(sessionData);
          updateSessionTitle(sessionData);
        } catch (e) {
          console.error(e);
        }
      } else {
        pendingAnswer[session._id] = topicPrompt;
        setPendingAnswer(pendingAnswer);
        updateSessionLastPrompt({ ...session, lastPrompt: new Date().toISOString() });
      }

      // Save stats session
      let overTimeLimit =
        messages.length > 0 ? new Date(new Date() - 60 * 60 * 1000) > new Date(messages[messages.length - 1].timestamp) : false;
      if (sessionId !== "new" && (!initSessionStats[sessionId] || overTimeLimit)) {
        let newSessionStats = await client.createSessionStats(sessionId, clientId);
        if (newSessionStats.ok) {
          initSessionStats[sessionId] = newSessionStats.data?._id;
          setInitSessionStats(initSessionStats);
          if (isTopicClick) {
            client.updateSessionStatsCountTopic(newSessionStats.data?._id);
          }
        }
      } else {
        if (initSessionStats[sessionId] !== true) {
          client.updateSessionStats(initSessionStats[sessionId]);
          if (isTopicClick) {
            client.updateSessionStatsCountTopic(initSessionStats[sessionId]);
          }
        }
      }

      // Send socket message
      const data = {
        mascotId,
        sessionId,
        clientId,
        userId: currentUser ? currentUser._id : null,
        content: topicPrompt,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        sessionStatsId: initSessionStats[sessionId],
      };

      socket.emit("sendMessage", data);
    },
    [socket, session, messages, mascotId, currentUser, newMessage, clientId, initSessionStats, pendingAnswer, currentMascot]
  );

  const loadSessions = useCallback(() => {
    if (currentMascot._id) {
      const limit = 10;
      setLoadMore(false);
      client.listSessions(currentMascot._id, clientId, page, limit).then((result) => {
        if (result.ok) {
          if (page === 1 && result.data.length === 0) {
            result.data.push({ _id: "new", title: "New session", lastPrompt: new Date().toISOString() });
          }

          if (page === 1) {
            updateSessions(result.data);
            // Select default session
            if (!sessionId) {
              selectSession(result.data[0]);
            }
          } else {
            addSessions(result.data);
          }

          if (result.data.length < limit) {
            setLoadMore(false);
          } else {
            setLoadMore(true);
          }
        }
      });
    }
  }, [currentMascot, clientId, page, sessionId]);

  useEffect(() => {
    if (currentMascot._id) {
      loadSessions();
    }
  }, [currentMascot, loadSessions]);

  const saveTitle = () => {
    if (!session.title) {
      toast.error("Session title is required");
      return;
    }
    if (session._id !== "new") {
      client.updateSessionTitle(session);
    }
    setEditingTitle(false);
    updateSessionTitle(session);
  };

  const onKeyDown = function (e) {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      if (!loading) {
        handleSubmitWs();
      }
      e.preventDefault();
    }
  };

  const doneTraining = function () {
    setTrainingMode(false);

    if (addedFeedback) {
      servicesClient.doneTraining(mascotId, session._id, { clientId });
      const promiseToastTraining = new Promise((resolve, reject) => {
        subscribe("FeedbackSubmitted", (data) => {
          if ("error" in data) {
            reject(data);
          } else {
            resolve();
          }
        });
      });

      toast.promise(promiseToastTraining, {
        pending: "Submitting Feedback",
        success: "Feedback Submitted!",
        error: {
          render({ data }) {
            return (
              <>
                Error Submitting Feedback!
                <br />
                <small>{data.value}</small>
              </>
            );
          },
        },
      });
    }
    setAddedFeedback(false);
  };

  const openMenu = (e) => {
    let node = e.target;
    if (!node) return;
    node.closest(classPopover).classList.add("not-hoverable");

    const selection = window.getSelection();
    if (selection && selection.toString()) return;

    if (document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(node);
      range.select();
    } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(node);
      selection.removeAllRanges();
      selection.addRange(range);
    } else {
      console.warn("Could not select text in node: Unsupported browser.");
    }
  };

  const togglePopup = (i) => {
    showedPopup[i] = !showedPopup[i];
    setShowedPopup([...showedPopup]);
  };

  const addRank = (i, msg, feedback, correctAnswer) => {
    setAddedFeedback(true);
    togglePopup(i);
    messages[i].feedback = feedback;
    messages[i].correctAnswer = correctAnswer;
    setMessages([...messages]);

    let sessionId = selectedSession._id;
    if (!initSessionStats[sessionId] && sessionId !== "new") {
      client.createSessionStats(sessionId, clientId).then((res) => {
        if (res.ok) {
          initSessionStats[sessionId] = res.data?._id;
          setInitSessionStats(initSessionStats);
          client.addFeedbackMessage(sessionId, msg.messageIndex, feedback, correctAnswer, res.data._id);
        }
      });
    } else {
      client.addFeedbackMessage(sessionId, msg.messageIndex, feedback, correctAnswer, initSessionStats[sessionId]);
    }
  };

  const showModalFeedback = (i) => {
    setFeedbackValueInit(messages[i].correctAnswer || messages[i].content);
    setFeedbackValue(messages[i].correctAnswer || messages[i].content);
    setShowModalFeedback(i);
  };

  const submitFeedback = () => {
    if (feedbackValue === feedbackValueInit) {
      setErrorFeedback("You must provide an edited response in order to submit the feedback");
    } else {
      addRank(isShowModalFeedback, messages[isShowModalFeedback], "negative", feedbackValue);
      setShowModalFeedback(false);
    }
  };

  const checkEmbedded = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  const getMascotDataLink = () => {
    if (!currentOrganization.features || !currentOrganization.features.advancedData) {
      return `/edit-mascot-data/${mascotId}`;
    } else if (!currentMascot.dataType) {
      return `/edit-mascot-data-type/${mascotId}`;
    } else if (currentMascot.dataType === "mixed") {
      return `/edit-mascot-data/${mascotId}`;
    } else if (currentMascot.dataType === "complexSpreadsheets") {
      return `/edit-mascot-data-complex/${mascotId}`;
    }
    return `/edit-mascot-data/${mascotId}`;
  };

  const exportData = async () => {
    let result = await client.exportSessionData(selectedSession._id, selectedDataFormat);
    if (result.ok) {
      downloadFile(result.data.fileName, result.data.content);
    }
  };

  const downloadFile = (fileName, content) => {
    const url = window.URL.createObjectURL(new Blob([content]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const openShareModal = async () => {
    if (!selectedSession.shareToken) {
      let res = await client.generateShareSessionToken(selectedSession._id);
      updateSessionToken(res.data);
    }
    setShowShareModal(true);
  };

  const archive = async () => {
    await client.archiveSession(selectedSession._id);
    removeSession(selectedSession._id);
    setShowOptionsPopover(false);
    setShowArchiveModal(false);
  };

  return (
    <div className="mascot-wrapper chat hide-bobbie">
      {!unavailable && (
        <MascotSidebar
          notReady={!isReady}
          showBadge={true}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          loading={loading}
          setPage={setPage}
          loadMore={loadMore}
        />
      )}

      <div className={classNames("mascot-chat-panel", currentMascot.theme && currentMascot.theme.darkTheme && "dark-theme")}>
        {/* Load Libs */}
        <link rel="preconnect" href="https://fonts.gstatic.com" />

        {currentMascot.theme && currentMascot.theme.fontFamily && (
          <link
            rel="stylesheet"
            href={`https://fonts.googleapis.com/css2?family=${currentMascot.theme.fontFamily.replace(
              /\s/g,
              "+"
            )}:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap`}
          />
        )}

        {unavailable ? (
          <div className="not-ready">
            {/* Unavailable state */}
            <h2>
              {unavailable === "Domain not allowed" ? "This mascot does not work on this site" : "This mascot is unavailable"}
            </h2>
          </div>
        ) : currentMascot.data && !isReady ? (
          <div className="not-ready">
            {/* Not ready state */}
            <img src={HourGlass} alt="not ready" />
            <h2>This mascot is not ready yet</h2>
            <p>Data is required to interact with a mascot.</p>
            {ownMascot && (
              <button className="action" onClick={() => history.push(getMascotDataLink())}>
                Add Data
              </button>
            )}
          </div>
        ) : (
          <>
            {/* Header chat */}

            <div className="chat-header">
              {/* Open Sidebar for Mobile */}
              <div className="chat-header-left">
                <div className="icon-btn open-sidebar" onClick={() => setSidebarOpen(!sidebarOpen)}>
                  <i className="icon-menu"></i>
                </div>
              </div>

              <div className="chat-header-middle">
                <div className="mobile-mascot-header">
                  <MascotImage mascot={currentMascot}></MascotImage>

                  {currentMascot.name && <h2>{currentMascot.name}</h2>}
                </div>

                <div className="chat-title">
                  {editingTitle ? (
                    <div className="edit-title">
                      <input
                        value={session.title}
                        autoFocus
                        onChange={(e) => setSession({ ...session, title: e.target.value })}
                      />
                      <button className="xsmall" onClick={() => saveTitle()}>
                        Save
                      </button>
                    </div>
                  ) : (
                    <>
                      {!loadingConversation && (
                        <div>
                          <h6>
                            {session.title || new moment(session.created).format("MM-DD-YYYY hh:mm")}{" "}
                            <i className="icon-edit" onClick={() => setEditingTitle(true)}></i>
                          </h6>
                          <span className="meta small">Started on {new moment(session.created).format("MMM Do, hh:mma")}</span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              {!showExcerpts && isAuthenticated && ownMascot && currentMascot._id && (
                <div className="chat-header-right">
                  {!trainingMode ? (
                    <div className="icon-btn neutral" onClick={() => setShowOptionsPopover(true)}>
                      <i className="icon-ellipses"></i>

                      {showOptionsPopover && (
                        <Popover close={() => setShowOptionsPopover(false)} className="right options-popover">
                          <div className="popover-item" onClick={() => setShowSessionStatsModal(true)}>
                            <i className="icon-info"></i>
                            Conversation Stats
                          </div>

                          <div className="popover-item" onClick={() => setTrainingMode(true)}>
                            <i className="icon-filter"></i>
                            Tune Mascot
                          </div>

                          <div className="popover-item" onClick={() => setShowExportModal(true)}>
                            <i className="icon-external-link"></i>
                            Export
                          </div>

                          <div className="popover-item" onClick={() => openShareModal()}>
                            <i className="icon-share"></i>
                            Share
                          </div>

                          <div className="popover-item danger" onClick={() => setShowArchiveModal(true)}>
                            <i className="icon-warn"></i>
                            Archive
                          </div>
                        </Popover>
                      )}
                    </div>
                  ) : (
                    <button className="xsmall" onClick={doneTraining}>
                      Done
                    </button>
                  )}
                </div>
              )}
            </div>

            {/* Chat container */}
            <div className="chat-wrapper" style={{ fontFamily: currentMascot.theme && currentMascot.theme.fontFamily }}>
              <div className="messages-wrapper" ref={chatWrapperEl}>
                {loadingConversation ? (
                  <p className="empty-message">
                    <Loader></Loader>
                  </p>
                ) : (
                  <>
                    {messages.length === 0 ? (
                      <p className="empty-message">Get started by asking {currentMascot.name} anything.</p>
                    ) : (
                      <div className="messages">
                        {messages.map((message, i) => {
                          return (
                            <MessageChat
                              key={i}
                              message={message}
                              mascot={currentMascot}
                              newMessageAnimation={newMessageAnimation}
                              trainingMode={trainingMode}
                              togglePopup={togglePopup}
                              i={i}
                              showedPopup={showedPopup}
                              addRank={addRank}
                              showModalFeedback={showModalFeedback}
                              setShowExcerpts={setShowExcerpts}
                              selectedMessage={selectedMessage}
                              chatWrapperEl={chatWrapperEl}
                            ></MessageChat>
                          );
                        })}

                        {/* Streaming message */}
                        {streamingMessage && selectedSession && streamingMessage[selectedSession._id] && (
                          <div className={classNames("message", "assistant", "streaming-message")}>
                            <div className="message-header">
                              <MascotImage mascot={currentMascot}></MascotImage>
                              <div className="message-header-title">
                                <h5>{currentMascot.name}</h5>
                              </div>
                            </div>

                            <div className="message-content not-hoverable">
                              <MDEditor.Markdown
                                linkTarget="_blank"
                                source={streamingMessage[selectedSession._id]}
                                data-color-mode="dark"
                                style={{
                                  color: currentMascot.theme && currentMascot.theme.accentColor,
                                  fontFamily: currentMascot.theme && currentMascot.theme.fontFamily,
                                }}
                              />
                            </div>
                          </div>
                        )}

                        <div ref={chatEndEl}></div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* Send Message */}
              <div
                className="new-message"
                style={{ borderColor: newMessageFocus && currentMascot.theme && currentMascot.theme.brandColor }}
              >
                <TextareaAutosize
                  ref={textareaEl}
                  maxRows={5}
                  className="new-message-field large"
                  placeholder="Ask me anything"
                  disabled={!isReady}
                  onKeyDown={onKeyDown}
                  onChange={(e) => setNewMessage(e.target.value)}
                  value={newMessage}
                  onFocus={() => setNewMessageFocus(!newMessageFocus)}
                  onBlur={() => setNewMessageFocus(!newMessageFocus)}
                />

                <button
                  className="positive"
                  onClick={() => handleSubmitWs()}
                  disabled={loading || newMessage.length === 0}
                  style={{ backgroundColor: currentMascot.theme && currentMascot.theme.brandColor }}
                >
                  <i className="icon-chevron-right"></i>
                </button>

                {/* Topics */}
                {currentMascot.topics && currentMascot.topics.length > 0 && (
                  <div className="chat-topics">
                    {currentMascot.topics.map((topic, i) => {
                      return (
                        <button disabled={loading} className="small" key={i} onClick={() => handleSubmitWs(topic.prompt, true)}>
                          {topic.name}
                        </button>
                      );
                    })}
                  </div>
                )}

                {loading && (
                  <span className="responding">
                    <Loader></Loader>

                    <span className="meta small">Generating Response</span>

                    {runningAnswer && (
                      <button className={classNames("xxsmall", runningAnswer && "cancel")} onClick={() => handleStopStream()}>
                        Cancel
                      </button>
                    )}
                  </span>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <MascotExcerpts message={showExcerpts} setShowExcerpts={setShowExcerpts} />
      <TrainingModeSidebar show={trainingMode} close={setTrainingMode} messages={messages.filter((m) => !!m.feedback)} />

      {showShareModal && (
        <Modal
          title="Share Session"
          isOpen={!!showShareModal}
          close={() => setShowShareModal(false)}
          size="small"
          className="edit-response"
        >
          <div className="input-group">
            <label>Direct link to session</label>

            <input
              type="text"
              readOnly
              defaultValue={process.env.REACT_APP_URL_APP + "/mascot/" + selectedSession.shareToken + "/chat"}
            ></input>
          </div>

          <button
            onClick={() =>
              toast.promise(
                navigator.clipboard.writeText(process.env.REACT_APP_URL_APP + "/mascot/" + selectedSession.shareToken + "/chat"),
                {
                  success: "Copied to clipboard",
                  error: "Error copying to clipboard",
                }
              )
            }
          >
            Copy Link
          </button>
        </Modal>
      )}

      {showExportModal && (
        <Modal
          title="Export Session Data"
          isOpen={!!showExportModal}
          close={() => setShowExportModal(false)}
          size="small"
          className="edit-response"
        >
          <div className="input-group">
            <label>Select a format to export</label>

            <div className="select">
              <select onChange={(e) => setSelectedDataFormat(e.target.value)}>
                <option value="json">JSON</option>
                <option value="csv">CSV</option>
              </select>
            </div>
          </div>

          <button onClick={exportData}>Download</button>
        </Modal>
      )}

      {showArchiveModal && (
        <Modal
          title="Archive Session"
          isOpen={!!showArchiveModal}
          close={() => setShowArchiveModal(false)}
          size="small"
          action={
            <button className="danger large wide" onClick={archive}>
              Archive
            </button>
          }
        >
          <div className="input-group">
            <label>Are you sure you want to archive this session?</label>
          </div>
        </Modal>
      )}

      {showSessionStatsModal && (
        <Modal
          title="Conversation Stats"
          isOpen={!!showSessionStatsModal}
          close={() => setShowSessionStatsModal(false)}
          size="large"
        >
          <button className="xsmall outline" onClick={refreshSessionStats}>
            Get Latest Stats
            {refreshingSessionStats && <Loader classNames="small"></Loader>}
          </button>

          <h5>Summary</h5>
          <p>{aggregatedStatsSessions?.summary}</p>

          <h5>Success Rating: {aggregatedStatsSessions?.satisfaction}/10</h5>

          <p>
            {aggregatedStatsSessions?.satisfaction === 1 && "This session had a very poor outcome."}
            {aggregatedStatsSessions?.satisfaction === 2 && "This session had a mostly negative outcome."}
            {aggregatedStatsSessions?.satisfaction === 3 && "This session had a somewhat negative outcome."}
            {aggregatedStatsSessions?.satisfaction === 4 && "This session had a slightly negative outcome."}
            {aggregatedStatsSessions?.satisfaction === 5 && "This session had a neutral outcome, but could have been better."}
            {aggregatedStatsSessions?.satisfaction === 6 &&
              "This session had a slightly positive outcome, but could have been better."}
            {aggregatedStatsSessions?.satisfaction === 7 &&
              "This session had a somewhat positive outcome, but could have been better."}
            {aggregatedStatsSessions?.satisfaction === 8 && "This session had a mostly positive outcome."}
            {aggregatedStatsSessions?.satisfaction === 9 && "This session had a very good outcome."}
            {aggregatedStatsSessions?.satisfaction === 10 && "This session had an excellent outcome."}
          </p>

          <h5>Message Count</h5>
          <p>{aggregatedStatsSessions?.prompts * 2} Messages</p>

          <h5>Unknown Answers: {aggregatedStatsSessions?.unknownAnswer.length}</h5>

          {aggregatedStatsSessions?.unknownAnswer.map((answer, i) => (
            <div className="blockquote-pair" key={i}>
              <blockquote className="prompt-preview">
                <span className="meta small">Prompt</span>
                <p>{answer.prompt?.trim()}</p>
              </blockquote>
              <blockquote className="response-preview">
                <span className="meta small">Response</span>
                <p>{answer.response?.trim()}</p>
              </blockquote>
            </div>
          ))}
        </Modal>
      )}

      {isShowModalFeedback && (
        <Modal
          title="Edit This Response"
          isOpen={!!isShowModalFeedback}
          close={() => setShowModalFeedback(false)}
          size="large"
          className="edit-response"
          action={
            <button className="action large" onClick={submitFeedback}>
              Submit
            </button>
          }
        >
          <p>
            Please provide a corrected answer. This helps the mascot learn to provide better responses when asked about this topic
            in the future.
          </p>

          <blockquote className="prompt-preview">
            <span className="meta small">Your prompt</span>
            <p>{messages[isShowModalFeedback - 1].content}</p>
          </blockquote>

          <div className="md-editor-wrapper" data-color-mode="light">
            <MDEditor
              height="400px"
              value={feedbackValue}
              onChange={(e) => setFeedbackValue(e)}
              autoFocus={true}
              preview="edit"
              hideToolbar="true"
              enableScroll="true"
            />
          </div>

          {errorFeedback && <div className="error">{errorFeedback}</div>}
        </Modal>
      )}

      {currentMascot.prompt_settings && currentMascot.prompt_settings.inline_actions && (
        <SelectTextPopup
          containerClass={classPopover}
          onAction={(prompt) => {
            handleSubmitWs(prompt);
          }}
        ></SelectTextPopup>
      )}
    </div>
  );
}
