import React, { useEffect, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import classNames from "classnames";
import Loader from "../Loader";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

export default function DoughnutChart({ data: inputData, title, className, value, valueLabel, label }) {
  const [data, setData] = useState(undefined);
  const hideColor = "#00151E";
  const options = {
    responsive: true,
    cutout: "75%",
    radius: 102,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          usePointStyle: true,
          color: "#f7f7f7",
          pointStyle: "rectRounded",
          padding: 18,
          font: {
            family: "Rubik",
          },
        },
        title: {
          padding: 10,
          display: true,
        },
      },
    },
  };

  useEffect(() => {
    if (inputData) {
      let data = {
        labels: inputData.map((d) => d.label + " - " + parseInt(d.perc) + "%"),
        backgroundColor: hideColor,
        datasets: [
          {
            data: inputData.map((d) => d.count),
            backgroundColor: inputData.map((d) => (d.hide ? hideColor : d.color)),
            hoverOffset: 0,
            borderRadius: inputData.map((d) =>
              d.hide ? hideColor : { outerStart: 200, outerEnd: 200, innerStart: 200, innerEnd: 200 }
            ),
            borderWidth: 0,
          },
        ],
      };
      setData(data);
    }
  }, [inputData]);

  return (
    <div className={classNames("chart", "donut-chart", className && className)}>
      <h4>{title}</h4>

      {data ? (
        data.datasets && data.datasets[0].data.reduce((acc, curr) => acc + curr, 0) > 0 ? (
          <>
            <div className="donut-chart-canvas">
              <Doughnut options={options} data={data} />

              {value &&
                <div className="chart-value">
                  <h2>{value}</h2>
                  
                  {valueLabel && 
                    <span className="meta small">{valueLabel}</span>
                  }
                </div>
              }
            </div>
            
            <div className="meta chart-label">{label}</div>
          </>
        ) : (
          <h5 className="no-data">No data yet</h5>
        )
      ) : (
        <Loader classNames="dark large"></Loader>
      )}
    </div>
  );
}
