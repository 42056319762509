import Header from "../components/Header";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../hooks/useAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { client } from "../services/api";

const schema = yup.object().shape({
  currentPass: yup.string().required("Please enter your current password"),
  newPass: yup
    .string()
    .required("Password required")
    .min(8, "Password must be 8 chars minimum")
    .matches(/(?=.*[A-Z])/, "Password must contain one uppercase character")
    .matches(/(?=.*[a-z])/, "Password must contain one lowercase character")
    .matches(/(?=.*[0-9])/, "Password must contain one number")
    .matches(/^\S+$/, "No spaces allowed")
    .matches(/(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/, "Password must contain one special character"),
  newPassConfirm: yup.string().required("Please confirm your new password"),
});

export default function ChangePassword() {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { currentUser } = useAuth();
  const [editPasswordError, setEditPasswordError] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  function onSubmit(data) {
    if (data.newPass !== data.newPassConfirm) {
      return setError("Passwords do not match.");
    } else {
      client.updatePassword(currentUser._id, data.currentPass, data.newPass).then((data) => {
        if (data.status === 200) {
          setSuccess(true);

          reset({
            currentPass: "",
            newPass: "",
            newPassConfirm: "",
          });

          setTimeout(() => {
            setSuccess(null);
          }, 4000);
        } else {
          setEditPasswordError("Wrong password.");
          setTimeout(() => {
            setEditPasswordError(null);
          }, 4000);
        }
      });
      return setError(null);
    }
  }

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div className="main-wrapper">
      <Header></Header>

      <div className="content-wrapper narrow reset-password">
        <div className="page-title">
          <h1>Change Password</h1>

          <button className="action" onClick={handleSubmit(onSubmit)}>Update</button>
        </div>

        <div className="input-group">
          <label className="light">Enter Current Password</label>
          <input className="outline" name="currentPass" type="password" {...register("currentPass")} onKeyDown={_handleKeyDown} />
          {errors.currentPass ? <div className="error">{errors.currentPass.message}</div> : null}
          {editPasswordError ? <div className="error">{editPasswordError}</div> : null}
        </div>

        <div className="input-group">
          <label className="light">New Password</label>
          <input className="outline" name="newPass" type="password" {...register("newPass")} onKeyDown={_handleKeyDown} />
          {errors.newPass ? <div className="error">{errors.newPass.message}</div> : null}
        </div>

        <div className="input-group">
          <label className="light">Re-enter New Password</label>
          <input className="outline" name="newPassConfirm" type="password" {...register("newPassConfirm")} onKeyDown={_handleKeyDown} />
          {errors.newPassConfirm ? <div className="error">{errors.newPassConfirm.message}</div> : null}
        </div>

        {error ? <p className="error">{error}</p> : null}
        {success ? <p className="success">Password updated.</p> : null}
      </div>
    </div>
  );
}
