import React, { useState } from "react";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Logo from "../images/logo-wiseox.svg";
import Popover from "./Popover.js";
import { setCurrentOrganization, useStoreContext } from "../context/store";

export default function Header() {
  const { currentUser, isAuthenticated } = useAuth();
  const [showAccountPopover, setShowAccountPopover] = useState(false);
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState("alphabetical");
  const [showOrgPopover, setShowOrgPopover] = useState(false);
  const { organizations, currentOrganization } = useStoreContext();
  const location = useLocation();
  const pathname = location.pathname;

  return (
    isAuthenticated && (
      <header className="header">
        <div className="logo-wrapper">
          <img src={Logo} alt="WiseOx Logo" />
        </div>

        <div className="header-item org-name" onClick={() => setShowOrgPopover(true)}>
          {currentOrganization.name}
          {organizations.length > 1 && <i className="icon-chevron-down"></i>}

          {showOrgPopover && organizations.length > 1 && (
            <Popover
              close={() => {
                setShowOrgPopover(false);
                setFilter("");
              }}
              className="orgs-menu"
            >
              <div className="filter-orgs">
                <input type="text" className="xsmall" onChange={(e) => setFilter(e.target.value)} placeholder="Search"></input>

                <div className="select-icon">
                  <i className="icon-sort"></i>
                  <select onChange={(e) => setSort(e.target.value)}>
                    <option value="alphabetical">Alphabetical</option>
                    <option value="created">Recently Created</option>
                  </select>
                </div>
              </div>

              {organizations
                .filter(
                  (o) =>
                    o.owner && o.owner.email === currentUser.email && o.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
                )
                .sort((a, b) => {
                  if (sort === "alphabetical") return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                  if (sort === "created") return a.created > b.created ? -1 : 1;
                  return 0;
                })
                .map((o) => (
                  <div className="popover-item my-org" key={o._id} onClick={() => setCurrentOrganization(o._id)}>
                    <div>{o.name}</div>
                    <div className="meta small">My organization</div>
                  </div>
                ))}
              {organizations
                .filter((o) => !o.owner || o.owner.email !== currentUser.email)
                .filter((o) => o.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                .sort((a, b) => {
                  if (sort === "alphabetical") return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                  if (sort === "created") return a.created > b.created ? -1 : 1;
                  return 0;
                })
                .map((o) => (
                  <div className="popover-item" key={o._id} onClick={() => setCurrentOrganization(o._id)}>
                    <div>{o.name}</div>
                    <div className="meta small"> {o.owner && o.owner.email}</div>
                  </div>
                ))}
            </Popover>
          )}
        </div>
        
        <div className="header-nav">
          <Link className={classNames("header-item", pathname === "/" && "active")} to="/">
            <i className="icon-mascot"></i> Mascots
          </Link>

          {currentOrganization.features && currentOrganization.features.insights && (
            <Link className={classNames("header-item", pathname === "/insights" && "active")} to="/insights">
              <i className="icon-graph"></i> Insights
            </Link>
          )}

          <div className="header-item" onClick={() => setShowAccountPopover(true)}>
            <i className="icon-account"></i> Account
            <i className="icon-chevron-down"></i>
            {showAccountPopover && (
              <Popover close={() => setShowAccountPopover(false)} className="right account-menu">
                <div className="current-user meta">{currentUser && currentUser.email}</div>

                <Link className="popover-item" to="/account">
                  <i className="icon-account"></i>
                  Manage Account
                </Link>

                {(!currentOrganization.disabled || currentUser.superadmin) && (
                  <Link className="popover-item" to="/administrators">
                    <i className="icon-users"></i>
                    Administrators
                  </Link>
                )}

                <Link className="popover-item" to="/changePassword">
                  <i className="icon-lock"></i>
                  Change Password
                </Link>

                <a rel="noreferrer" className="popover-item" href="https://wiseox.com/contact" target="_blank">
                  <i className="icon-help"></i>
                  Help
                </a>

                <Link className="popover-item" to="/logout">
                  <i className="icon-sign-out"></i>
                  Sign Out
                </Link>

                <a rel="noreferrer" className="popover-item terms" href="https://wiseox.com/legal/terms-of-service" target="_blank">
                  Terms of Service
                </a>
              </Popover>
            )}
          </div>
        </div>
      </header>
    )
  );
}
