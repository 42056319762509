import React, { useState } from "react";
import classNames from "classnames";
import { useParams, useHistory } from "react-router-dom";
import { useStoreContext, updateMascot } from "../context/store";
import MascotSidebar from "../components/MascotSidebar";
import { client } from "../services/api";

import mixedDataIcon from "../images/data-type-mixed.svg";
import complexDataIcon from "../images/data-type-complex.svg";

export default function EditMascotDataType() {
  const { mascotId } = useParams();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState();
  const { currentMascot } = useStoreContext();
  const history = useHistory();

  const setDataType = () => {
    currentMascot.dataType = selectedDataType;
    client.updateMascot({ _id: currentMascot._id, dataType: selectedDataType });
    updateMascot(currentMascot);

    if (currentMascot.dataType === "mixed") {
      history.push(`/edit-mascot-data/${mascotId}`);
    } else if (currentMascot.dataType === "complexSpreadsheets") {
      history.push(`/edit-mascot-data-complex/${mascotId}`);
    }
  };

  return (
    <div className="mascot-wrapper">
      <MascotSidebar editing sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}></MascotSidebar>

      <div className="edit-mascot">
        <div className="icon-btn open-sidebar" onClick={() => setSidebarOpen(!sidebarOpen)}>
          <i className="icon-menu"></i>
        </div>

        <div className="select-data-type">
          <h2>Select Data Type</h2>

          <p>
            Mixed Content is most common. If you need your mascot to perform queries against advanced datasets, choose Complex Spreadsheets.
          </p>

          <div
            className={classNames("data-type", selectedDataType === "mixed" && "selected")}
            onClick={() => setSelectedDataType("mixed")}
          >
            <img src={mixedDataIcon} alt="Mixed Data Type" />

            <div>
              <p>
                <b>Mixed Content (most common)</b>
              </p>
              <p className="small">
                Includes text documents, HTML, images, presentations, simple spreadsheets, videos, audio, and URLs.
              </p>
            </div>

            <div className="radio">
              <input type="radio" id="mixed" name="data-type" checked={selectedDataType === "mixed"} readOnly />
              <span></span>
            </div>
          </div>

          <div
            className={classNames("data-type", selectedDataType === "complexSpreadsheets" && "selected")}
            onClick={() => setSelectedDataType("complexSpreadsheets")}
          >
            <img src={complexDataIcon} alt="Complex Spreadsheets Data Type" />

            <div>
              <p>
                <b>Complex Spreadsheets</b>
              </p>
              <p className="small">
                For spreadsheets that have complex formatting, such as nested tables and merged cells. <br />Supported file types: .xls, .xlsx, .csv, .tsv
              </p>
            </div>

            <div className="radio">
              <input type="radio" id="complex" name="data-type" checked={selectedDataType === "complexSpreadsheets"} readOnly />
              <span></span>
            </div>
          </div>

          <hr />

          <p className="meta large warning">
            You can always switch back to a previously used data type. We will hold on your data unless you remove it.
          </p>

          <button className="action large" onClick={() => setDataType()} disabled={!selectedDataType}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
