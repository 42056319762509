import React from "react";
import * as ReactModal from "react-modal";
import classNames from "classnames";

ReactModal.setAppElement("#root");

export default function Modal({ children, title, size, isOpen, close, action, className, topActions }) {
  return (
    <ReactModal 
      className={classNames(
        'modal',
        size && size,
        isOpen && 'show',
        className
      )}
      overlayClassName="modal-wrapper" 
      isOpen={isOpen} 
      onRequestClose={close}
    >
      <div className="modal-top-actions">
        {topActions}
        <div className="icon-btn close-modal" onClick={close}>
          <i className="icon-close"></i>
        </div>
      </div>

      {title &&
        <h2 className="modal-title">{title}</h2>
      }

      {children &&
        <div className="modal-content">{children}</div>
      }
      
      {action && 
        <div className="modal-actions">{action}</div>
      }
    </ReactModal>
  );
}
