import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Bottleneck from "bottleneck";
import { client } from "../services/api";
import { useAuth } from "../hooks/useAuth";
import { useStoreContext, setCurrentMascot, updateMascot, addMascotTasks } from "../context/store";
import { servicesClient } from "../services/servicesApi";
import MascotSidebar from "../components/MascotSidebar";
import DragAndDrop from "../ui/DragAndDrop";
import Loader from "../components/Loader";
import mixedDataIcon from "../images/data-type-mixed.svg";

export default function EditMascotData() {
  const { currentUser } = useAuth();
  const { mascotId } = useParams();
  const { currentMascot, mascots, uploadInfo, currentOrganization } = useStoreContext();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [newUrl, setNewUrl] = useState("");
  const [updatingTask, setUpdatingTask] = useState({});
  const [pendingTasks, setPendingTasks] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const history = useHistory();
  const dndRef = useRef();

  const limiter = useMemo(() => new Bottleneck({ maxConcurrent: 2 }), []);

  useEffect(() => {
    setCurrentMascot(mascotId);
  }, [mascots, mascotId]);

  useEffect(() => {
    const embeddingTask = currentMascot.tasks && currentMascot.tasks.find((task) => task.type === "generateEmbeddingTask");
    if (embeddingTask) {
      setUpdatingTask(embeddingTask);
      setUpdateLoading(true);
    } else {
      setUpdatingTask({});
      setUpdateLoading(false);
    }

    const pendingTask =
      currentMascot.tasks &&
      currentMascot.tasks.find(
        (task) => task.status === "queued" && (task.type === "parseFileTask" || task.type === "crawlPageTask")
      );

    if (pendingTask) {
      setPendingTasks(true);
    } else {
      setPendingTasks(false);
    }
  }, [currentMascot]);

  const refreshMascot = useCallback(async () => {
     await client.getMascot(mascotId).then((result) => {
      if (result.ok) {
        let newMascot = currentMascot;
        newMascot.data = result.data.data;
        newMascot.tasks = result.data.tasks;
        updateMascot(newMascot);
      }
    });
  }, [mascotId, currentMascot]);

  const uploadFile = useCallback(
    async (file) => {
      if (!currentUser) return;
      const result = await servicesClient.dataUpload(mascotId, file, currentUser._id);
      if (!result.ok) {
        console.log("Upload Error! " + result.originalError);
        toast.error(() => (
          <>
            <b>{file.name}</b>: {String(result.originalError)}.
          </>
        ));
        return;
      }
      if (result.data.error) {
        return;
      }
      toast.success(() => (
        <>
          <b>{result.data.meta.filename}</b> uploaded!
        </>
      ));
      addMascotTasks(currentMascot._id, [result.data]);
    },
    [mascotId, currentMascot, currentUser]
  );

  const fileHandler = useCallback(
    (files) => {
      if (files.length === 0) return;
      const supportedFiles = Array.from(files).filter((file) => {
        if (
          currentMascot.data?.uploads.some(
            (u) => u.name === file.name && (u.mascot_data_type_at_upload === "mixed" || !u.mascot_data_type_at_upload)
          )
        ) {
          toast.error(() => (
            <>
              <b>{file.name}</b>: Duplicate files cannot be uploaded.
            </>
          ));
          return false;
        }
        if (uploadInfo.supportedFormats && !uploadInfo.supportedFormats.some((ext) => file.name.toLocaleLowerCase().endsWith(ext))) {
          toast.error(() => (
            <>
              <b>{file.name}</b>: File extension not supported.
            </>
          ));
          return false;
        }
        if (uploadInfo.maxContentLength && uploadInfo.maxContentLength < file.size) {
          const maxSizeMb = (uploadInfo.maxContentLength / (1024 * 1024)).toFixed(2);
          toast.error(() => (
            <>
              <b>{file.name}</b>: File size limit exceeded ({maxSizeMb} MB).
            </>
          ));
          return false;
        }
        return true;
      });
      if (supportedFiles.length === 0) return;
      const id = toast.loading(() => (
        <>
          Uploading <b>{supportedFiles.length > 1 ? `${supportedFiles.length} files` : supportedFiles[0].name}</b>
        </>
      ));
      const uploadPromises = supportedFiles
        .sort(() => 0.5 - Math.random())
        .map((file) => limiter.schedule(() => uploadFile(file)));
      Promise.allSettled(uploadPromises).finally(() => {
        dndRef.current && dndRef.current?.reset();
        toast.dismiss(id);
      });
      limiter.on("done", (i) => {
        const jobs = limiter.jobs().length;
        toast.update(id, {
          render: () => (
            <>
              Uploading{" "}
              <b>
                {jobs} {jobs > 1 ? `files` : `file`}
              </b>
            </>
          ),
        });
      });
    },
    [currentMascot, uploadInfo, limiter, uploadFile]
  );

  const handleDeleteUpload = async (uploadId) => {
    const deleteResult = await servicesClient.dataDelete(mascotId, uploadId);
    await refreshMascot();
    toast.success(() => <>Document <b>{deleteResult.data ? deleteResult.data.name : ""}</b> deleted!</>);
  };

  const handleDeleteTask = async (taskId) => {
    await servicesClient.taskDelete(mascotId, taskId);
    refreshMascot();
  };

  const handleStopTask = async (taskId) => {
    await servicesClient.taskStop(mascotId, taskId);
    if (taskId === updatingTask.id) {
      setUpdatingTask({});
      setUpdateLoading(false);
    }
    refreshMascot();
  };

  const onUpdateAction = useCallback(async () => {
    if (!currentUser) return;
    setUpdateLoading(true);
    const result = await servicesClient.embeddingGenerate(mascotId, { userId: currentUser._id });
    if (result.ok && result.data) {
      setUpdatingTask(result.data);
      updateMascot({ ...currentMascot, tasks: [...currentMascot.tasks, result.data] });
      toast.info(() => <>Mascot update started!</>);
    } else {
      setUpdateLoading(false);
      toast.error("Server error!");
    }
  }, [mascotId, currentUser, currentMascot]);

  const addUrl = async () => {
    if (!currentUser) return;
    setNewUrl("");
    let url = newUrl;
    if (!(url.indexOf("http://") === 0 || url.indexOf("https://") === 0)) {
      url = "https://" + url;
    }

    let result = await servicesClient.addUrl(mascotId, url, currentUser._id);
    if (!result.ok) {
      return toast.error("Server error!");
    }

    let newTasks = result.data?.filter((u) => !u.error) || [];
    updateMascot({ ...currentMascot, tasks: [...currentMascot.tasks, ...newTasks] });

    if (!result.ok) {
      toast.error("Crawling error!");
    } else {
      if (result.data[0].error) {
        toast.error(() => (
          <>
            <b>{result.data[0].meta.url}</b>: Duplicate url cannot be crawled.
          </>
        ));
      } else {
        toast.success(() => "Crawling started");
      }
    }
  };

  const handleRefreshUrl = async (upload) => {
    if (!currentUser) return;
    const result = await servicesClient.refreshUrl(mascotId, upload._id, currentUser._id);
    if (!result.ok) {
      toast.error("Crawling error!");
    } else {
      toast.success(() => "Crawling started");

      currentMascot.data.uploads.map((u) => {
        if (u._id === upload._id) {
          u.refreshing = true;
        }
        return u;
      });
      updateMascot(currentMascot);
    }
  };

  const changeDataType = () => {
    currentMascot.dataType = null;
    updateMascot(currentMascot);
    client.updateMascot({ _id: currentMascot._id, dataType: null });
    history.push("/edit-mascot-data-type/" + currentMascot._id);
  };

  return (
    <div className="mascot-wrapper">
      <MascotSidebar editing sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}></MascotSidebar>

      <div className="edit-mascot">
        <div className="icon-btn open-sidebar" onClick={() => setSidebarOpen(!sidebarOpen)}>
          <i className="icon-menu"></i>
        </div>

        {!currentOrganization.features || !currentOrganization.features.advancedData ? (
          <div className="edit-mascot-header">
            <h1>Manage Data</h1>
          </div>
        ) : (
          <div className="edit-mascot-header">
            <img src={mixedDataIcon} alt="Mixed Data Type" />
            <h1>Mixed Content</h1>
            <button onClick={changeDataType} className="small outline">
              Change Data Type
            </button>
          </div>
        )}

        {currentMascot.data && currentMascot.data.stale && (
          <div className="mascot-data-alert">
            <i className="icon-warn"></i>
            <p>Your mascot needs to be updated with your new data.</p>
            <span>
              <button className="small dark" onClick={onUpdateAction} disabled={updateLoading || pendingTasks}>
                {pendingTasks ? <>Processing Files...</> : <>Update Mascot</>}
                {updateLoading && <Loader classNames="dark" />}
              </button>
              {updatingTask.id && (
                <button className="small" onClick={() => handleStopTask(updatingTask.id)}>
                  Cancel
                </button>
              )}
            </span>
          </div>
        )}

        <div className="content-wrapper full-width col-2">
          <div className="content-col">
            <h3>Upload Content</h3>

            <p>
              Provide content that will make your mascot a subject matter expert. Ensure the content is comprehensive and can be
              understood if read by a human. The more data the better. (Maximum file size: 50MB)
            </p>

            <p className="meta warning">Note: Please ensure all documents are done 'parsing' before clicking "Update Mascot".</p>

            <DragAndDrop ref={dndRef} fileHandler={fileHandler}></DragAndDrop>

            <span className="meta small">
              <i className="icon-lock"></i> Your data is end-to-end encrypted, and never shared. Read our{" "}
              <a href="https://wiseox.com/legal/privacy" target="_blank" rel="noreferrer">
                Privacy Notice
              </a>
              .
            </span>

            <div className="data-list">
              <span className="meta">Previously Uploaded</span>
              <input
                type="text"
                className="small"
                placeholder="Search uploaded documents"
                value={filter}
                onChange={(event) => setFilter(event.target.value)}
              />

              <div className="list">
                <ul>
                  {currentMascot.tasks &&
                    currentMascot.tasks
                      .filter(
                        (task) =>
                          task.type === "parseFileTask" &&
                          (!task.meta.mascot_data_type_at_upload || task.meta.mascot_data_type_at_upload === "mixed")
                      )
                      .reverse()
                      .map((task) => {
                        return task.status === "failed" ? (
                          <li className={"list-item failed"} key={task.id} title={task.meta.error || ""}>
                            <div className="list-item-name">{task.meta.filename}</div>

                            <div className="list-item-actions">
                              <i className="icon-remove" onClick={() => handleDeleteTask(task.id)}></i>
                            </div>
                          </li>
                        ) : (
                          <li className={"list-item"} key={task.id} title={task.meta.filename}>
                            <Loader />
                            <div className="list-item-name">{task.meta.filename}</div>

                            <div className="list-item-actions">
                              <button className="xsmall" onClick={() => handleStopTask(task.id)}>
                                Cancel
                              </button>
                            </div>
                          </li>
                        );
                      })}
                  {currentMascot.data &&
                    currentMascot.data.uploads &&
                    currentMascot.data.uploads
                      .filter((doc) => {
                        if (doc.url) return false;
                        if (!doc["mascot_data_type_at_upload"]) doc["mascot_data_type_at_upload"] = "mixed";
                        if (doc["mascot_data_type_at_upload"] !== "mixed") return false;
                        return doc.name.toLowerCase().includes(filter.toLocaleLowerCase()) || filter === "";
                      })
                      .reverse()
                      .map((doc) => {
                        return (
                          <li className="list-item clickable" key={doc._id} title={doc.name}>
                            <div
                              className="list-item-name"
                              onClick={() => history.push(`/edit-mascot-doc/${currentMascot._id}/${doc._id}`)}
                            >
                              {doc.name}
                            </div>

                            <div className="list-item-actions">
                              {doc.name.endsWith(".xlsx") ||
                              doc.name.endsWith(".ods") ||
                              doc.name.endsWith(".csv") ||
                              doc.name.endsWith(".tsv") ? (
                                <></>
                              ) : (
                                <i
                                  className="icon-edit"
                                  onClick={() => history.push(`/edit-mascot-doc/${currentMascot._id}/${doc._id}#editing`)}
                                ></i>
                              )}

                              {!updateLoading && <i className="icon-remove" onClick={() => handleDeleteUpload(doc._id)}></i>}
                            </div>
                          </li>
                        );
                      })}
                </ul>
              </div>
            </div>
          </div>

          <div className="content-col">
            <h3>Website URLs</h3>

            <p>
              Provide URLs to web pages you want to train your mascot on, such as info about products and services, brand company
              info, legal, and documentation. You can also click the URL to see the data that was captured.
            </p>

            <p className="meta warning">
              Note: URLs must be publicly accessible and not behind a login. Certain sites may block this feature.
            </p>

            <div className="input-combo over">
              <input
                type="text"
                className="small"
                placeholder="https://www.example.com"
                value={newUrl}
                onChange={(event) => setNewUrl(event.target.value)}
              />

              <button className="small" onClick={addUrl}>
                Add URL
              </button>
            </div>

            <div className="list">
              <ul>
                {currentMascot.tasks &&
                  currentMascot.tasks
                    .filter((task) => task.type === "crawlPageTask")
                    .reverse()
                    .map((task) => {
                      return task.status === "failed" ? (
                        <li className={"list-item failed"} key={task.id} title={task.meta.url}>
                          <div className="list-item-name">{task.meta.url}</div>

                          <div className="list-item-actions">
                            <i className="icon-remove" onClick={() => handleDeleteTask(task.id)}></i>
                          </div>
                        </li>
                      ) : (
                        <li className={"list-item"} key={task.id} title={task.meta.url}>
                          <Loader />
                          <div className="list-item-name">{task.meta.url}</div>

                          <div className="list-item-actions">
                            <button className="xsmall" onClick={() => handleStopTask(task.id)}>
                              Cancel
                            </button>
                          </div>
                        </li>
                      );
                    })}
                {currentMascot.data &&
                  currentMascot.data.uploads &&
                  currentMascot.data.uploads
                    .filter((doc) => doc.url)
                    .reverse()
                    .map((doc) => {
                      return doc.refreshing ? (
                        <li className="list-item disabled" key={doc._id}>
                          <Loader />
                          <div className="list-item-name">{doc.url}</div>
                        </li>
                      ) : (
                        <li className="list-item clickable" key={doc._id}>
                          <div
                            className="list-item-name"
                            onClick={() => history.push(`/edit-mascot-doc/${currentMascot._id}/${doc._id}`)}
                          >
                            {doc.url}
                          </div>
                          <div className="list-item-actions">
                            {!updateLoading && <i className="icon-reload" onClick={() => handleRefreshUrl(doc)}></i>}
                            {!updateLoading && <i className="icon-remove" onClick={() => handleDeleteUpload(doc._id)}></i>}
                          </div>
                        </li>
                      );
                    })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
