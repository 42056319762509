import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useStoreContext, setCurrentMascot, updateMascot } from "../context/store";
import FileInput from "../ui/FileInput";
import MascotSidebar from "../components/MascotSidebar";
import Traitsfield from "../components/Traitsfield";
import MascotImage from "../components/MascotImage";
import { client } from "../services/api";

const sheetSchema = Yup.object().shape({
  name: Yup.string().required("Name required"),
  description: Yup.string().required("Description required"),
});

export default function EditMascot() {
  const { currentMascot, mascots } = useStoreContext();
  const { mascotId } = useParams();
  const traits = [
    "Funny",
    "Agreeable",
    "Serious",
    "Cheerful",
    "Creative",
    "Empathetic",
    "Helpful",
    "Enthusiastic",
    "Logical",
    "Optimistic",
    "Witty",
    "Charismatic",
    "Collaborative",
    "Friendly",
    "Precise",
    "Polite",
    "Honest",
    "Objective",
    "Sarcastic",
  ].map((t) => ({ name: t }));
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const imagePreview = useRef();

  const handleChangeMascot = (value) => {
    const mascot = {
      ...currentMascot,
      ...value,
    };

    updateMascot(mascot);
  };

  useEffect(() => {
    setCurrentMascot(mascotId);
  }, [mascots, mascotId]);

  const { handleChange, values, setFieldValue, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...currentMascot,
      name: currentMascot.name || "",
      description: currentMascot.description || "",
      traits: currentMascot.traits || [],
      image: currentMascot.image || null,
      public: currentMascot.public || false,
    },
    validationSchema: sheetSchema,
    validate: handleChangeMascot,
  });

  const handleTraitsChange = (items) => {
    setFieldValue("traits", items);
  };

  const handleImageChange = async (event) => {
    if (event.currentTarget.files.length > 0) {
      const blob = URL.createObjectURL(event.currentTarget.files[0]);
      imagePreview.current.src = blob;

      const data = new FormData();
      data.append("file", event.currentTarget.files[0]);
      const res = await client.updateMascotImage(currentMascot._id, data);
      if (res.ok) {
        const mascot = {
          ...currentMascot,
          image: res.data.image,
          version: res.data.version,
        };
        updateMascot(mascot);
      }
    }
  };

  const handlePublicChange = (e) => {
    updateMascot({ ...currentMascot, public: e.target.checked });
  };

  return (
    <div className="mascot-wrapper">
      <MascotSidebar 
        editing
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      ></MascotSidebar>

      <div className="edit-mascot">
        <div 
          className="icon-btn open-sidebar"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <i className="icon-menu"></i>
        </div>
        
        <div className="edit-mascot-header">
          <h1>Edit Mascot</h1>
        </div>

        <div className="content-wrapper full-width col-2">
          <div className="content-col">
            <div className="input-group">
              <label>Mascot Name</label>
              <input type="text" name="name" value={values.name} onChange={handleChange} placeholder="e.g. ZipBot" />
              {errors.name && touched.name ? <div className="error">{errors.name}</div> : null}
            </div>

            <div className="input-group">
              <label>Description</label>
              <input
                type="text"
                name="description"
                value={values.description}
                onChange={handleChange}
                placeholder="Employee chat bot"
              />
              {errors.description && touched.description ? <div className="error">{errors.description}</div> : null}
            </div>

            <div className="input-group">
              <label>Personality Traits (enter up to 2)</label>
              <Traitsfield traits={traits} value={currentMascot.traits} onChange={handleTraitsChange} />
            </div>

            <div className="switch-wrapper">
              <label>Enable Public Mascot</label>
              <span className="meta small">
                This will make the mascot available to anyone who has the URL, QR Code, or access to where the mascot is embedded.
              </span>
              <div className="switch">
                <input type="checkbox" checked={values.public} onChange={handlePublicChange} />
                <span></span>
                <span></span>
              </div>
            </div>
          </div>

          <div className="content-col">
            <div className="preview-window square">
              <div className="preview-content">
                <span className="meta">Mascot Avatar</span>

                <FileInput label="Upload Image" onChange={handleImageChange}></FileInput>

                <div className="mascot-image-preview">
                  <MascotImage mascot={currentMascot} ref={imagePreview}></MascotImage>
                  <p>Image must be 1:1 ratio (square), and cannot be larger than 5mb.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
