import React, { useEffect, useState, useMemo } from "react";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { useAuth } from "../hooks/useAuth";
import { client } from "../services/api";
import * as Yup from "yup";

import BgImg from "../images/sign-in-bg.png";
import Logo from "../images/logo-wiseox.svg";

const initialFormState = {
  firstName: "",
  lastName: "",
  organization: "",
  email: "",
  password: "",
  invitationToken: "",
};

const sheetSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name required"),
  lastName: Yup.string().required("Last Name required"),
  organization: Yup.string().required("Organization name required"),
  email: Yup.string()
    .required("Email Address is required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Please enter a valid email address"),
  password: Yup.string()
    .required("Password required")
    .min(8, "Password must be 8 chars minimum")
    .matches(/(?=.*[A-Z])/, "Password must contain one uppercase character")
    .matches(/(?=.*[a-z])/, "Password must contain one lowercase character")
    .matches(/(?=.*[0-9])/, "Password must contain one number")
    .matches(/^\S+$/, "No spaces allowed")
    .matches(/(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/, "Password must contain one special character"),
});

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export function SignUp() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const query = useQuery();
  const history = useHistory();

  let { signUp, loading, error, handleError, signupError, handleSignupError } = useAuth();
  let { handleChange, handleSubmit, values, errors, touched, setFieldValue } = useFormik({
    initialValues: initialFormState,
    validationSchema: sheetSchema,
    onSubmit: handleSubmission,
  });

  useEffect(() => {
    if (query.has("invitationToken")) {
      setFieldValue("invitationToken", query.get("invitationToken"));
      client.getInvite(query.get("invitationToken")).then((response) => {
        if (response.ok) {
          setFieldValue("organization", response.data.organization.name);
          setFieldValue("email", response.data.email);
        }
      });
    }
  }, [query, setFieldValue]);

  useEffect(() => {
    handleError(null);
  }, [handleError]);

  function handleSubmission(values, { setSubmitting }) {
    setSubmitting(false);
    signUp(values).then((valid) => {
      if (valid) {
        history.push("/signupConfirmed", { email: values.email });
      }
    });
  }

  function togglePasswordVisisble() {
    setPasswordVisible(!passwordVisible);
  }

  function handleEmailChange(e) {
    if (e.target.value && values.email.length) {
      handleSignupError(null);
    }

    handleChange(e);
  }

  return (
    <div className="auth-wrapper" style={{ backgroundImage: `url(${BgImg})` }}>
      <div className="logo-wrapper">
        <img className="logo" src={Logo} alt="WiseOx Logo" />

        {query.has("invitationToken") ? (
          <NavLink to={`/login?invitationToken=${query.get("invitationToken")}`}>
            <i className="icon-chevron-left"></i> Sign In
          </NavLink>
        ) : (
          <NavLink to="/login">
            <i className="icon-chevron-left"></i> Sign In
          </NavLink>
        )}
      </div>

      <div className="signup-form">
        <h1>Sign Up For WiseOx</h1>

        <input type="hidden" name="invitationToken" value={values.invitationToken} onChange={handleChange} />

        <div className="input-group">
          <input
            type="text"
            name="firstName"
            required
            value={values.firstName}
            placeholder="First Name"
            onChange={handleChange}
            tabIndex="1"
            autoComplete="do-not-autofill"
          />
          {errors.firstName && touched.firstName ? <div className="error">{errors.firstName}</div> : null}
        </div>

        <div className="input-group">
          <input
            type="text"
            name="lastName"
            required
            value={values.lastName}
            placeholder="Last Name"
            onChange={handleChange}
            tabIndex="2"
            autoComplete="off"
          />
          {errors.lastName && touched.lastName ? <div className="error">{errors.lastName}</div> : null}
        </div>

        <div className="input-group">
          <input
            type="text"
            name="organization"
            required
            disabled={query.has("invitationToken")}
            value={values.organization}
            placeholder="Organization"
            onChange={handleChange}
            tabIndex="3"
          />
          {errors.organization && touched.organization ? <div className="error">{errors.organization}</div> : null}
        </div>

        <div className="input-group">
          <input
            type="email"
            name="email"
            required
            disabled={query.has("invitationToken")}
            value={values.email}
            placeholder="Email Address"
            onChange={handleEmailChange}
            tabIndex="4"
          />
          {errors.email && touched.email ? <div className="error">{errors.email}</div> : null}
        </div>

        <div className="input-group">
          <div className="input-combo over">
            <input
              type={!passwordVisible ? "password" : "text"}
              name="password"
              required
              value={values.password}
              placeholder="Set a Password"
              onChange={handleChange}
              tabIndex="5"
            />

            <div className="icon-btn" onClick={togglePasswordVisisble}>
              {!passwordVisible ? <i className="icon-eye-off"></i> : <i className="icon-eye"></i>}
            </div>
          </div>

          {errors.password && touched.password ? <div className="error">{errors.password}</div> : null}
        </div>

        {error && <div className="error">{error}</div>}

        <p className="small">By signing up, you agree to the WiseOx <a href="https://wiseox.com/legal/terms-of-service" target="_blank" rel="noreferrer">Terms of Service</a></p>

        <button className="action large" tabIndex="6" disabled={loading || !values.email} onClick={handleSubmit}>
          Get Started
        </button>

        {signupError ? (
          <div className="error">
            {signupError}
          </div>
        ) : null}
      </div>
    </div>
  );
}
