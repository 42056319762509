import apisauce from "apisauce";
//import { setupCache } from 'axios-cache-adapter';

/*const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});*/

export const create = (baseURL = process.env.REACT_APP_URL_API) => {
  let search = window.location.search;
  let searchParams = new URLSearchParams(search);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const api = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
      "wiseox-current-org": localStorage.getItem("currentOrgId"),
      "source-origin": window.self !== window.top ? "embed" : searchParams.get("source-origin") || "",
      "parent-domain":
        window.location.ancestorOrigins && window.location.ancestorOrigins[0]
          ? window.location.ancestorOrigins[0]
          : document.referrer,
    },
    timeout: 60000,
    //adapter: cache.adapter,
  });

  const signUp = (data) => {
    return api.post(`/api/user/register`, data);
  };

  const verifyAccount = (userId, token) => {
    return api.post(`/api/user/${userId}/verify`, { token });
  };

  const logIn = (data) => {
    return api.post(`/api/login`, data);
  };

  const setPassword = (token) => {
    return api.post(`/api/setPassword`, {
      token,
    });
  };

  const recoverPassword = (email) => {
    return api.post(`/api/recoverPassword`, { mail: email });
  };

  const resetPassword = ({ token, password }) => {
    return api.post(`/api/resetPassword`, { token, password });
  };

  const me = () => {
    return api.post(`/api/verifyToken`, {});
  };

  const updateUser = (data) => {
    return api.put(`/api/user/${data._id}`, data);
  };

  const checkUserByEmail = (email) => {
    return api.get(`/api/user/${email}`, {});
  };

  const getUserOrganizations = (userId) => {
    return api.get(`/api/user/${userId}/organizations`, {});
  };

  const getOrganization = (organizationId, opts = {}) => {
    return api.get(`/api/organization/${organizationId}`, opts);
  };

  const updateOrganization = (data) => {
    return api.put(`/api/organization/${data._id}`, data);
  };

  const getInvites = (orgId) => {
    return api.get(`/api/organization/${orgId}/invites`);
  };

  const removeInvite = (orgId, inviteId) => {
    return api.delete(`/api/organization/${orgId}/invites/${inviteId}`);
  };

  const getInvite = (token) => {
    return api.get(`/api/organization/invite/${token}`, {});
  };

  const acceptInvite = (token, userId) => {
    return api.get(`/api/organization/accept-invite/${token}/${userId}`, {});
  };

  const inviteUser = (orgId, data) => {
    return api.post(`/api/organization/${orgId}/invite`, data);
  };

  const updatePassword = (userId, currentPass, newPass) => {
    return api.put(`/api/user/${userId}/password`, { passwordNew: newPass, passwordOld: currentPass });
  };

  const listSessions = (mascotId, clientId, page = 1, limit = 10) => {
    return api.get(`/api/mascot/${mascotId}/sessions`, { clientId, page, limit });
  };

  const listGuestSessions = (mascotId, guestId) => {
    return api.get(`/api/mascot/${mascotId}/guest/${guestId}/sessions`, {});
  };

  const getSession = (sessionId) => {
    return api.get(`/api/session/${sessionId}`, {});
  };

  const createSession = (data) => {
    return api.post(`/api/session`, data);
  };

  const getMascot = (mascotId, opts = {}) => {
    return api.get(`/api/mascot/${mascotId}`, opts);
  };

  const getMascots = (orgId, opts = {}) => {
    return api.get(`/api/organization/${orgId}/mascots`, opts);
  };

  const getMascotUploads = (mascotId, opts = {}) => {
    return api.get(`/api/mascot/${mascotId}/uploads`, opts);
  };

  const createMascot = (data) => {
    return api.post(`/api/mascot`, data);
  };

  const updateMascot = (data) => {
    return api.put(`/api/mascot/${data._id}`, data);
  };

  const updateMascotImage = (mascotId, data) => {
    return api.put(`/api/mascot/${mascotId}/image`, data);
  };

  const archiveMascot = (mascotId) => {
    return api.put(`/api/mascot/${mascotId}/archive`, {});
  };

  const updateSessionTitle = (session) => {
    return api.put(`/api/session/${session._id}`, { title: session.title });
  };

  const addFeedbackMessage = (sessionId, messageIndex, feedback, correctAnswer, sessionStatsId) => {
    return api.put(`/api/session/${sessionId}/addFeedbackMessage`, { messageIndex, feedback, correctAnswer, sessionStatsId });
  };

  const exportSessionData = (sessionId, format) => {
    return api.get(`/api/session/${sessionId}/export/${format}`);
  };

  const generateShareSessionToken = (sessionId) => {
    return api.post(`/api/session/${sessionId}/generateSessionToken`, {});
  };

  const getSessionByShareToken = (shareToken) => {
    return api.get(`/api/session/byShareToken/${shareToken}`);
  };

  const archiveSession = (sessionId) => {
    return api.post(`/api/session/${sessionId}/archive`, {});
  };

  // Stats

  const createSessionStats = (sessionId, clientId) => {
    return api.post(`/api/sessionStats/`, { sessionId, clientId });
  };

  const updateSessionStats = (idStat) => {
    return api.post(`/api/sessionStats/${idStat}`, {});
  };

  const updateSessionStatsCountTopic = (idStat) => {
    return api.post(`/api/sessionStats/${idStat}/countTopic`, {});
  };

  const updateSessionStatsCountLinksShown = (idStat) => {
    return api.post(`/api/sessionStats/${idStat}/countLinksShown`, {});
  };

  const updateSessionStatsCountLinksClicked = (idStat) => {
    return api.post(`/api/sessionStats/${idStat}/countLinksClicked`, {});
  };

  const getStatsSessionsBySession = (sessionId) => {
    return api.post(`/api/sessionStats/session/${sessionId}`, { timezone });
  };

  const getStatsSessionsAndUsers = (mascotId, timeframe) => {
    return api.post(`/api/sessionStats/mascot/${mascotId}/sessionsAndUsers`, {
      timeframe,
      timezone,
    });
  };

  const getStatsSessionsAndUsersOrg = (orgId, timeframe) => {
    return api.post(`/api/sessionStats/organization/${orgId}/sessionsAndUsers`, { timeframe, timezone });
  };

  const getStatsSessionsOrgByDay = (orgId, timeframe) => {
    return api.post(`/api/sessionStats/organization/${orgId}/sessionsByDay`, {
      timeframe,
      timezone,
    });
  };

  const getStatsMascotPerDay = (orgId, day) => {
    return api.post(`/api/sessionStats/organization/${orgId}/activeMascots`, { day, timezone });
  };

  const getStatsUserTypeByOrg = (orgId, timeframe) => {
    return api.post(`/api/sessionStats/organization/${orgId}/userType`, { timeframe, timezone });
  };

  const getStatsUserLocationByOrg = (orgId, timeframe) => {
    return api.post(`/api/sessionStats/organization/${orgId}/userLocation`, { timeframe, timezone });
  };

  const getStatsUserOriginByOrg = (orgId, timeframe) => {
    return api.post(`/api/sessionStats/organization/${orgId}/userOrigin`, { timeframe, timezone });
  };

  const getStatsSessionsMascotByDay = (mascotId, timeframe) => {
    return api.post(`/api/sessionStats/mascot/${mascotId}/sessionsByDay`, { timeframe, timezone });
  };

  const getStatsUserTypeByMascot = (mascotId, timeframe) => {
    return api.post(`/api/sessionStats/mascot/${mascotId}/userType`, { timeframe, timezone });
  };

  const getStatsUserLocationByMascot = (mascotId, timeframe) => {
    return api.post(`/api/sessionStats/mascot/${mascotId}/userLocation`, { timeframe, timezone });
  };

  const getStatsUserOriginByMascot = (mascotId, timeframe) => {
    return api.post(`/api/sessionStats/mascot/${mascotId}/userOrigin`, { timeframe, timezone });
  };

  const getStatsUserBrowserByMascot = (mascotId, timeframe) => {
    return api.post(`/api/sessionStats/mascot/${mascotId}/userBrowser`, { timeframe, timezone });
  };

  const getStatsSessionsDurationByMascot = (mascotId, timeframe) => {
    return api.post(`/api/sessionStats/mascot/${mascotId}/sessionsDuration`, { timeframe, timezone });
  };

  const getStatsSessionsUnknownAnswersByMascot = (mascotId, timeframe) => {
    return api.post(`/api/sessionStats/mascot/${mascotId}/unknownAnswers`, { timeframe, timezone });
  };

  const getStatsSessionsSuccessRatingByMascot = (mascotId, timeframe) => {
    return api.post(`/api/sessionStats/mascot/${mascotId}/successRating`, { timeframe, timezone });
  };

  const getMascotTopTopics = (mascotId) => {
    return api.post(`/api/sessionStats/mascot/${mascotId}/topTopics`, {});
  };

  const getStatsSessionsTuningByMascot = (mascotId, timeframe) => {
    return api.post(`/api/sessionStats/mascot/${mascotId}/sessionsTuning`, { timeframe, timezone });
  };

  const getStatsSessionsLinksByMascot = (mascotId, timeframe) => {
    return api.post(`/api/sessionStats/mascot/${mascotId}/sessionsLinks`, { timeframe, timezone });
  };

  const getStatsSessionsAveragePromptsByMascot = (mascotId, timeframe) => {
    return api.post(`/api/sessionStats/mascot/${mascotId}/averagePrompts`, { timeframe, timezone });
  };

  const getStatsSessionsEventsByMascot = (mascotId, timeframe) => {
    return api.post(`/api/sessionStats/mascot/${mascotId}/events`, { timeframe, timezone });
  };

  return {
    // Security
    verifyAccount,
    me,
    signUp,
    logIn,
    setPassword,
    recoverPassword,
    resetPassword,
    updatePassword,

    // Session
    createSession,
    getSession,
    listSessions,
    listGuestSessions,
    updateSessionTitle,
    addFeedbackMessage,
    exportSessionData,
    generateShareSessionToken,
    getSessionByShareToken,
    archiveSession,

    // Mascot
    createMascot,
    getMascot,
    getMascotUploads,
    updateMascot,
    updateMascotImage,
    archiveMascot,
    getMascots,

    // User
    getUserOrganizations,
    getOrganization,
    updateOrganization,
    updateUser,

    // Organization
    checkUserByEmail,
    getInvite,
    acceptInvite,
    inviteUser,
    getInvites,
    removeInvite,

    // Stats
    createSessionStats,
    updateSessionStats,
    updateSessionStatsCountTopic,
    updateSessionStatsCountLinksShown,
    updateSessionStatsCountLinksClicked,
    getStatsSessionsBySession,

    // Stats Org
    getStatsSessionsAndUsersOrg,
    getStatsSessionsOrgByDay,
    getStatsSessionsAndUsers,
    getStatsMascotPerDay,
    getStatsUserTypeByOrg,
    getStatsUserLocationByOrg,
    getStatsUserOriginByOrg,

    // Stats Mascot
    getStatsSessionsMascotByDay,
    getStatsUserTypeByMascot,
    getStatsUserLocationByMascot,
    getStatsUserOriginByMascot,
    getStatsUserBrowserByMascot,
    getStatsSessionsDurationByMascot,
    getStatsSessionsUnknownAnswersByMascot,
    getStatsSessionsSuccessRatingByMascot,
    getStatsSessionsTuningByMascot,
    getStatsSessionsLinksByMascot,
    getStatsSessionsAveragePromptsByMascot,
    getStatsSessionsEventsByMascot,
    getMascotTopTopics,

    // Utils
    api,
  };
};

export const client = create();
