import React, { useEffect, useState, useCallback } from "react";

export default function Traitsfield(props) {
  const _traits = props.traits;
  const _onChange = props.onChange;
  const [value, setValue] = useState(props.value ? props.value.map((t) => t.name).join(" ") : "");
  const [traits, setTraits] = useState([]);
  const [suggestedTraits, setSuggestedTraits] = useState([]);

  const suggestTraits = useCallback((traits, value) => {
    setSuggestedTraits(
      traits
        .filter((t) => !value.includes(t.name))
        .sort(() => (Math.random() > 0.5 ? 1 : -1))
        .slice(0, 6)
    );
  }, []);

  useEffect(() => {
    if (traits.map((t) => t.name).join("") !== _traits.map((t) => t.name).join("")) {
      setTraits(_traits);
      suggestTraits(_traits, value);
    }
  }, [_traits, traits, suggestTraits, value]);

  const addTrait = (trait) => {
    if (value.split(" ").filter((v) => v !== "").length >= 2) return;

    if (value.indexOf(trait.name) < 0) {
      let v = `${value} ${trait.name}`.replace(/\s+/g, " ");
      if (v.split(" ").length < 5) v += " ";

      setValue(v);
      _onChange &&
        _onChange(
          v
            .trim()
            .split(" ")
            .map((t) => ({ name: t }))
        );
    }

    setSuggestedTraits(suggestedTraits.filter((t) => t.name !== trait.name));
  };

  const onChange = (e) => {
    const v = e.target.value;
    if (v.split(" ").length > 5) return;

    setValue(v);
    _onChange &&
      _onChange(
        v
          .trim()
          .split(" ")
          .map((t) => ({ name: t }))
      );
  };

  return (
    <div className="traitfield">
      <input type="text" value={value} placeholder="Separate by spaces or select below" onChange={onChange} />

      <div className="suggestions-wrapper">
        <div className="suggestions-header">
          <span className="meta">Suggestions</span>

          <button className="transparent small" onClick={() => suggestTraits(traits, value)}>
            More <i className="icon-reload"></i>
          </button>
        </div>

        <div className="suggestions-items-wrapper">
          {suggestedTraits.map((t, i) => (
            <span key={i} className="button small" onClick={() => addTrait(t)}>
              {t.name}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}
