import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { MascotList } from "../components/MascotList";
import { useStoreContext, setCurrentMascot, selectSession } from "../context/store";
import { useAuth } from "../hooks/useAuth";
import Modal from "../components/Modal";
import CreateMascot from "../components/CreateMascot";
import Loader from "../components/Loader";
import { subscribe } from "../services/events";
import { useHistory } from "react-router-dom";

import MissingMascot from "../images/missing-mascot.png";

export default function Home() {
  const { currentUser } = useAuth();
  const { mascots, mascotsLoading, currentOrganization } = useStoreContext();
  const [showCreateMascot, setShowCreateMascot] = useState();
  const history = useHistory();

  useEffect(() => {
    setCurrentMascot();
    selectSession();

    subscribe("noOrg", () => {
      history.push("/missing-org");
    });
  }, [history]);

  return (
    <div className="main-wrapper">
      <Header></Header>

      {currentOrganization.disabled && !currentUser?.superadmin ? (
        <div className="no-mascots">
          <div className="disabled">
            <h2>This organization has been suspended</h2>
            <p>
              It looks like your trial has run out, or this organization has been suspended for another reason. If you have
              questions or concerns, please contact us.
            </p>
            <a className="button positive" href="https://wiseox.com/contact" target="_blank" rel="noreferrer">
              Contact Us
            </a>
          </div>
        </div>
      ) : mascotsLoading || mascots.length > 0 ? (
        <div className="content-wrapper">
          {!mascotsLoading && (
            <div className="page-title">
              <h1>{mascots.length} Mascots</h1>

              <button className="action" onClick={() => setShowCreateMascot(true)}>
                New Mascot
              </button>
            </div>
          )}

          {mascotsLoading ? <Loader classNames="large dark" /> : <MascotList />}
        </div>
      ) : (
        <div className="no-mascots">
          <div className="build-cta">
            <img alt="Missing Mascot" src={MissingMascot} />
            <h2>Your First Mascot</h2>
            <p>Funny? Serious? Empathetic? Build your mascot with a persona that works best for its role.</p>

            <button className="action" onClick={() => setShowCreateMascot(true)}>
              Build Mascot
            </button>
          </div>
        </div>
      )}

      <Modal size="large" className="create-mascot" isOpen={showCreateMascot} close={() => setShowCreateMascot(false)}>
        <CreateMascot></CreateMascot>
      </Modal>
    </div>
  );
}
