import React, { useEffect, useState } from "react";

// Context
import { useStoreContext } from "../context/store";
import { client } from "../services/api";

// Component
import Header from "../components/Header";
import BarChart from "../components/charts/BarChart";
import ModalSessionsByDay from "../components/ModalSessionsByDay";
import TableChart from "../components/charts/TableChart";
import PieChart from "../components/charts/PieChart";

export default function Insights() {
  const [intervalTime, setIntervalTime] = useState(7);
  const { currentOrganization } = useStoreContext();
  const [dataHeader, setDataHeader] = useState({});
  const [dataChart, setDataChart] = useState();
  const [dataUserType, setDataUserType] = useState();
  const [dataUserLocation, setDataUserLocation] = useState();
  const [dataUserOrigin, setDataUserOrigin] = useState();
  const [isShowModalDailySessions, setShowModalDailySessions] = useState();

  useEffect(() => {
    const fetchData = async () => {
      client.getStatsSessionsAndUsersOrg(currentOrganization._id, intervalTime).then((res) => {
        if (res.ok) setDataHeader(res.data);
      });
      client.getStatsSessionsOrgByDay(currentOrganization._id, intervalTime).then((res) => {
        if (res.ok) setDataChart(res.data);
      });
      client.getStatsUserTypeByOrg(currentOrganization._id, intervalTime).then((res) => {
        if (res.ok) setDataUserType(addColorUserType(res.data));
      });
      client.getStatsUserLocationByOrg(currentOrganization._id, intervalTime).then((res) => {
        if (res.ok) setDataUserLocation(res.data);
      });
      client.getStatsUserOriginByOrg(currentOrganization._id, intervalTime).then((res) => {
        if (res.ok) setDataUserOrigin(addColorUserOrigin(res.data));
      });
    };

    if (currentOrganization._id) {
      fetchData();
    }
  }, [intervalTime, currentOrganization]);

  const getIntervalTimeLabel = () => {
    if (intervalTime === "0") {
      return "All History";
    } else if (intervalTime === "1") {
      return "Today";
    } else {
      return intervalTime + " Day History";
    }
  };

  const addColorUserType = (data) => {
    for (let item of data) {
      if (item._id === "guest") {
        item.color = "#0ED0DC";
      } else if (item._id === "admin") {
        item.color = "#DC0E7D";
      } else if (item._id === "slack") {
        item.color = "#006DD4";
      } else if (item._id === "-1") {
        item.color = "#676767";
      }
    }
    return data;
  };

  const addColorUserOrigin = (data) => {
    for (let item of data) {
      if (item._id === "direct") {
        item.color = "#00D465";
      } else if (item._id === "slack") {
        item.color = "#006DD4";
      } else if (item._id === "embed") {
        item.color = "#D40057";
      } else if (item._id === "qr") {
        item.color = "#C03B0B";
      } else if (item._id === "-1") {
        item.color = "#676767";
      }
    }
    return data;
  };

  return (
    <div className="main-wrapper">
      <Header></Header>

      <div className="dashboard-wrapper">
        <div className="dashboard-hero">
          <div className="insight-header">
            <div className="insight-header-info">
              <div className="insight-header-left">
                <div>
                  <b>{getIntervalTimeLabel()}</b>
                </div>
                <div>{dataHeader.mascots} Mascots</div>
                <div>{dataHeader.sessions} Sessions</div>
                <div>{dataHeader.users} Users</div>
              </div>

              <div className="insight-header-right">
                <span className="meta small">Time frame</span>
                <div className="select xsmall dark">
                  <select value={intervalTime} onChange={(e) => setIntervalTime(e.target.value)}>
                    <option value={7}>Last 7 Days</option>
                    <option value={30}>Last 30 Days</option>
                    <option value={0}>All History</option>
                  </select>
                </div>
              </div>
            </div>

            {/* <div className="meta small">Data shown only for mascots with at least one or more session</div> */}
          </div>

          <BarChart
            title="Daily User Sessions"
            description="A session is when a user sends a prompt in a chat, and expires after 1 hour of inactivity or page reload."
            className="primary"
            borderColor="rgba(0,0,0,0)"
            data={dataChart}
            onClick={setShowModalDailySessions}
          ></BarChart>
        </div>

        <div className="charts-wrapper col-3">
          <PieChart title="User Type By Session" data={dataUserType}></PieChart>
          <TableChart title="Users By Location" data={dataUserLocation}></TableChart>
          <PieChart title="User Origin By Session" data={dataUserOrigin}></PieChart>
        </div>
      </div>

      {isShowModalDailySessions && (
        <ModalSessionsByDay
          isShowModalDailySessions={isShowModalDailySessions}
          setShowModalDailySessions={setShowModalDailySessions}
        ></ModalSessionsByDay>
      )}
    </div>
  );
}
