import React, { createContext, useReducer, useContext, useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { client } from "../services/api";
import { servicesClient } from "../services/servicesApi";
import { subscribe, unsubscribe, publish } from "../services/events";

let localStorageSupported = false;
try {
  localStorageSupported = window.localStorage && true;
} catch (e) {}

export const StoreContext = createContext();
//FIXME: fix this hack
let dispatch;
const initialStore = {
  sessions: [],
  selectedSession: undefined,
  mascots: [],
  currentMascot: {},
  ownMascot: false,
  organizations: [],
  currentOrganization: {},
  uploadInfo: {},
};

// Mascot
export function addMascot(mascot) {
  return dispatch({ type: "ADD_MASCOT", mascot });
}

export function removeMascot(mascot_id) {
  return dispatch({ type: "REMOVE_MASCOT", mascot_id });
}

export function setCurrentMascot(mascot_id, mascot) {
  return dispatch({ type: "SET_CURRENT_MASCOT", mascot_id, mascot });
}

export function updateMascot(mascot) {
  return dispatch({ type: "UPDATE_MASCOT", mascot });
}

export function addMascotTasks(mascot_id, tasks) {
  return dispatch({ type: "ADD_MASCOT_TASKS", mascot_id, tasks });
}

export function updateMascots(mascots) {
  return dispatch({ type: "UPDATE_MASCOTS", mascots });
}

// Organization
export function updateOrganizations(organizations) {
  return dispatch({ type: "UPDATE_ORGANIZATIONS", organizations });
}

export function setCurrentOrganization(organization_id) {
  return dispatch({ type: "SET_CURRENT_ORGANIZATION", organization_id });
}

export function updateOrganization(organization) {
  return dispatch({ type: "UPDATE_ORGANIZATION", organization });
}

// Sessions
export function updateSessions(sessions) {
  return dispatch({ type: "UPDATE_SESSIONS", sessions });
}

export function addSessions(sessions) {
  return dispatch({ type: "ADD_SESSIONS", sessions });
}

export function addSession(session) {
  return dispatch({ type: "ADD_SESSION", session });
}

export function removeSession(session_id) {
  return dispatch({ type: "REMOVE_SESSION", session_id });
}

export function selectLastSession() {
  return dispatch({ type: "SELECT_LAST_SESSION" });
}

export function selectSession(session) {
  return dispatch({ type: "SELECT_SESSION", session });
}

export function updateSessionTitle(session) {
  return dispatch({ type: "UPDATE_SESSION_TITLE", session });
}

export function updateSessionLastPrompt(session) {
  return dispatch({ type: "UPDATE_SESSION_LAST_PROMPT", session });
}

export function updateSessionToken(session) {
  return dispatch({ type: "UPDATE_SESSION_TOKEN", session });
}

export function resetStore() {
  return dispatch({ type: "RESET_STORE" });
}

export function updateUploadInfo(uploadInfo) {
  return dispatch({ type: "UPDATE_UPLOAD_INFO", uploadInfo });
}

export function storeReducer(state, action) {
  switch (action.type) {
    // Mascot
    case "ADD_MASCOT":
      return { ...state, mascots: [...state.mascots, action.mascot] };
    case "REMOVE_MASCOT":
      return { ...state, mascots: state.mascots.filter((m) => m._id !== action.mascot_id), currentMascot: {} };
    case "SET_CURRENT_MASCOT":
      if (action.mascot) {
        return { ...state, currentMascot: action.mascot, sessions: [], ownMascot: action.mascot.ownMascot };
      }
      if (state.mascots.length === 0 || state.currentMascot._id === action.mascot_id) return state;
      let currentMascot = {};
      if (action.mascot_id) currentMascot = state.mascots.find((m) => m._id === action.mascot_id) || {};
      return { ...state, ownMascot: true, currentMascot: currentMascot || {}, sessions: [] };
    case "UPDATE_MASCOT":
      const mascots = state.mascots.map((m) => (m._id === action.mascot._id ? action.mascot : m));
      if (mascots.length === 0) {
        return { ...state, mascots: [action.mascot], ownMascot: true, currentMascot: action.mascot };
      }
      let newCurrentMascot = { ...(mascots.find((m) => m._id === action.mascot._id) || action.mascot) };
      return { ...state, mascots: mascots, currentMascot: newCurrentMascot };
    case "ADD_MASCOT_TASKS":
      return storeReducer(state, {
        type: "UPDATE_MASCOT",
        mascot: state.mascots.map((m) => {
          if (m._id === action.mascot_id) {
            const _tasks = action.tasks.filter((_t) => !m.tasks.map((t) => t.id).includes(_t.id));
            m.tasks = [...m.tasks, ..._tasks];
          }
          return m;
        }),
      });
    case "UPDATE_MASCOTS":
      return { ...state, mascots: action.mascots };
    case "UPDATE_SESSIONS":
      return { ...state, sessions: action.sessions };
    case "ADD_SESSIONS":
      return { ...state, sessions: state.sessions.concat(action.sessions) };

    // Organization
    case "UPDATE_ORGANIZATIONS":
      return { ...state, organizations: action.organizations };
    case "SET_CURRENT_ORGANIZATION":
      if (state.organizations.length === 0 || state.currentOrganization._id === action.organization_id) return state;
      let currentOrganization = {};
      if (action.organization_id) currentOrganization = state.organizations.find((o) => o._id === action.organization_id) || {};
      localStorageSupported && localStorage.setItem("currentOrgId", currentOrganization._id);
      return { ...state, currentOrganization: currentOrganization || {} };
    case "UPDATE_ORGANIZATION":
      const organizations = state.organizations.map((o) => (o._id === action.organization._id ? action.organization : o));
      if (organizations.length === 0) {
        localStorageSupported && localStorage.setItem("currentOrgId", action.organization._id);
        return { ...state, organizations: [action.organization], currentOrganization: action.organization };
      }
      const currentOrg = organizations.find((o) => o._id === action.organization._id) || action.organization;
      localStorageSupported && localStorage.setItem("currentOrgId", currentOrg._id);
      return {
        ...state,
        organizations: organizations,
        currentOrganization: currentOrg,
      };

    // Sessions
    case "ADD_SESSION":
      if (state.selectedSession?._id === "new") {
        state.selectedSession = action.session;
      }

      return {
        ...state,
        selectedSession: state.selectedSession,
        sessions: [action.session].concat(state.sessions.filter((s) => s._id !== "new")),
      };
    case "UPDATE_SESSION_TITLE":
      return {
        ...state,
        sessions: state.sessions.map((s) => (s._id === action.session._id ? { ...s, title: action.session.title } : s)),
      };
    case "UPDATE_SESSION_LAST_PROMPT":
      return {
        ...state,
        sessions: state.sessions.map((s) => (s._id === action.session._id ? { ...s, lastPrompt: new Date().toISOString() } : s)),
      };
    case "UPDATE_SESSION_TOKEN":
      return {
        ...state,
        sessions: state.sessions.map((s) => (s._id === action.session._id ? { ...s, shareToken: action.session.shareToken } : s)),
        selectedSession: action.session,
      };
    case "SELECT_SESSION":
      return { ...state, selectedSession: action.session };
    case "REMOVE_SESSION":
      state.sessions = state.sessions.filter((s) => s._id !== action.session_id);
      let selectedSession = state.sessions ? state.sessions[0] : undefined;
      publish("changeSession", { sessionId: selectedSession ? selectedSession._id : "new" });
      return { ...state, sessions: state.sessions, selectedSession: selectedSession };
    case "SELECT_LAST_SESSION":
      return { ...state, selectedSession: state.sessions ? state.sessions[0] : undefined };

    case "RESET_STORE":
      return initialStore;
    case "UPDATE_UPLOAD_INFO":
      return { ...state, uploadInfo: action.uploadInfo };
    default:
      return state;
  }
}

function StoreProvider(props) {
  const { currentUser } = useAuth();
  const [store, dispatchFn] = useReducer(storeReducer, initialStore);
  const [mascotsLoading, setMascotsLoading] = useState(true);

  dispatch = dispatchFn;

  // Init Mascot and sessions
  useEffect(() => {
    // Set Mascots
    if (currentUser && store.currentOrganization._id) {
      setMascotsLoading(true);
      client.getMascots(store.currentOrganization._id).then((result) => {
        result.ok && updateMascots(result.data);
        setMascotsLoading(false);
      });
    }
  }, [currentUser, store.currentOrganization]);

  useEffect(() => {
    servicesClient.uploadInfo().then((result) => {
      if (result.ok) {
        updateUploadInfo(result.data);
      }
    });
  }, []);

  useEffect(() => {
    // Set Organizations
    if (currentUser && currentUser._id) {
      client.getUserOrganizations(currentUser._id).then((result) => {
        if (result.ok) {
          updateOrganizations(result.data);
          if (result.data.length > 0) {
            let paramOrg = new URLSearchParams(window.location.search).get("selectOrg");
            const currentOrgId = paramOrg || (localStorageSupported && localStorage.getItem("currentOrgId"));
            if (currentOrgId) {
              let orgFound = false;
              for (let o of result.data) {
                if (o._id === currentOrgId) {
                  orgFound = true;
                }
              }

              if (!orgFound) {
                setCurrentOrganization(result.data[0]._id);
              } else {
                client.getOrganization(currentOrgId).then((result) => {
                  result.ok && setCurrentOrganization(result.data._id);
                });
              }
            } else {
              setCurrentOrganization(result.data[0]._id);
            }
          } else {
            // window.location.replace("/missing-org");
            // window.history.pushState("page2", "Title", "/missing-org");
            // history.push("/missing-org");
            publish("noOrg");
          }
        }
      });
    } else {
      resetStore();
      setMascotsLoading(true);
    }
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {
    const refreshMascot = (data) => {
      client
        .getMascot(data.detail.result?.mascotId || data.detail.result?._id.$oid || data.detail.meta?.mascotId)
        .then((result) => {
          if (!result.ok) return;
          let newMascot = {};
          for (let m of store.mascots) {
            if (m._id === result.data?._id) {
              newMascot = m;
            }
          }
          newMascot.data = result.data.data;
          newMascot.tasks = result.data.tasks;

          updateMascot(newMascot);
        });
    };

    subscribe("parseFileTask", refreshMascot);
    subscribe("crawlPageTask", refreshMascot);
    subscribe("crawlPageRefreshTask", refreshMascot);
    subscribe("generateEmbeddingTask", refreshMascot);
    return () => {
      unsubscribe("parseFileTask", refreshMascot);
      unsubscribe("crawlPageTask", refreshMascot);
      unsubscribe("crawlPageRefreshTask", refreshMascot);
      unsubscribe("generateEmbeddingTask", refreshMascot);
    };
  }, [store.mascots]);

  const storeData = { ...store, mascotsLoading };
  return <StoreContext.Provider value={storeData} {...props} />;
}

function useStoreContext() {
  return useContext(StoreContext);
}

export { StoreProvider, useStoreContext };
