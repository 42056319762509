import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStoreContext, setCurrentMascot, updateMascot } from "../context/store";
import classNames from "classnames";
import MascotSidebar from "../components/MascotSidebar";
import MascotImage from "../components/MascotImage";
import ColorPicker from "../ui/ColorPicker";
import moment from "moment";

// Theme fonts
export const fontList = [
  "Aleo",
  "Antic Slab",
  "Arvo",
  "Barlow",
  "Bitter",
  "Cairo",
  "Crete Round",
  "Crimson Text",
  "Domine",
  "Josefin Sans",
  "Lato",
  "Lora",
  "Lexend",
  "Martel",
  "Merriweather",
  "Montserrat",
  "Noto Serif",
  "Nunito Sans",
  "Open Sans",
  "Oswald",
  "Poppins",
  "PT Sans",
  "PT Serif",
  "Quicksand",
  "Raleway",
  "Roboto",
  "Roboto Slab",
  "Rubik",
  "Source Sans Pro",
  "Source Serif Pro",
  "Work Sans",
  "Zilla Slab",
];

export default function EditMascotTheme() {
  const { mascotId } = useParams();
  const { currentMascot, mascots } = useStoreContext();
  const [fontDropdownVisible, setFontDropdownVisible] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Load current mascot
  useEffect(() => {
    setCurrentMascot(mascotId);
  }, [mascots, mascotId]);

  // Update Mascot
  const setValue = (key, value) => {
    const mascot = currentMascot;
    if (!mascot._id) return;
    if (!value) return;
    if (mascot.theme[key] === value) return;

    mascot.theme[key] = value;
    updateMascot(mascot);
  };

  // Font select
  const fonts = fontList.map((font, index) => {
    return (
      <div className="option" onClick={() => setValue("fontFamily", font)} key={index} style={{ fontFamily: String(font) }}>
        {font}
      </div>
    );
  });

  const loadFonts = fontList.map((font, i) => {
    let truncatedFontName = font.replace(/\s/g, "+");

    return <link rel="stylesheet" key={i} href={`https://fonts.googleapis.com/css2?family=${truncatedFontName}&display=swap`} />;
  });

  const handleCloseFontDropdown = () => {
    setFontDropdownVisible(false);
    document.removeEventListener("click", handleCloseFontDropdown);
  };

  const handleFontDropdown = (e) => {
    e.stopPropagation();
    if (fontDropdownVisible) return handleCloseFontDropdown();

    setFontDropdownVisible(true);
    document.addEventListener("click", handleCloseFontDropdown);
  };

  const handleDarkTheme = (e) => {
    updateMascot({ 
      ...currentMascot, 
      theme: {
        ...currentMascot.theme,
        darkTheme: e.target.checked 
      }
    });
  }

  return (
    <div className="mascot-wrapper">
      <MascotSidebar 
        editing
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      ></MascotSidebar>

      <div className="edit-mascot">
        <div 
          className="icon-btn open-sidebar"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <i className="icon-menu"></i>
        </div>

        <div className="edit-mascot-header">
          <h1>Theme</h1>
        </div>
        
        <div className="content-wrapper full-width col-2">
          <div className="content-col">
            <h3>Color Settings</h3>

            <ColorPicker
              label="Brand Color"
              defaultValue={(currentMascot.theme && currentMascot.theme.brandColor) || ""}
              handleThemeColor={(e) => setValue("brandColor", e)}
            />

            <ColorPicker
              label="Text Color"
              defaultValue={(currentMascot.theme && currentMascot.theme.accentColor) || ""}
              handleThemeColor={(e) => setValue("accentColor", e)}
            />

            <div className="input-group">
              <label>Font Family</label>

              <link rel="preconnect" href="https://fonts.gstatic.com" />

              {loadFonts}

              <div className="dropdown">
                <input
                  readOnly
                  type="text"
                  onClick={handleFontDropdown}
                  value={currentMascot.theme && currentMascot.theme.fontFamily}
                  style={{ fontFamily: currentMascot.theme && currentMascot.theme.fontFamily }}
                />
                <div className={classNames("dropdown-options", fontDropdownVisible && "active")}>{fonts}</div>
              </div>
            </div>

            <div className="switch-wrapper">
              <label>Enable Dark Theme</label>
              <span className="meta small">This will invert the theme of the mascot to a dark tone. You may need to adjust your colors above to match.</span>
              <div className="switch">
                <input 
                  type="checkbox" 
                  defaultChecked={(currentMascot.theme && currentMascot.theme.darkTheme)}
                  onChange={handleDarkTheme} 
                />
                <span></span>
                <span></span>
              </div>
            </div>
          </div>

          <div className="content-col">
            <div 
              className={classNames(
                'preview-window square mascot-chat-panel',
                currentMascot.theme && currentMascot.theme.darkTheme && 'dark-theme'
              )}
            >
              <div className="preview-content">
                <span className="meta">Preview</span>

                {/* CHAT PREVIEW */}
                <div
                  className="chat-wrapper"
                  style={{ fontFamily: currentMascot.theme && currentMascot.theme.fontFamily }}
                >
                  <div className="messages-wrapper">
                    <div className="messages">
                      <div className="message user" style={{ borderColor: currentMascot.theme && currentMascot.theme.brandColor }}>
                        <div className="message-header">
                          <div
                            className="user-avatar"
                            style={{backgroundColor: currentMascot.theme && currentMascot.theme.brandColor}}
                          ><h3>U</h3></div>
                          <div className="message-header-title">
                            <h5>User</h5>
                            <span className="meta small">{new moment().format("MMM Do, hh:mma")}</span>
                          </div>
                        </div>
                        <div className="message-content">
                          <div className="wmde-markdown">
                            <p style={{ color: currentMascot.theme && currentMascot.theme.accentColor }}>
                              This is message sent from the user
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="message assistant">
                        <div className="message-header">
                          <MascotImage mascot={currentMascot}></MascotImage>
                          
                          <div className="message-header-title">
                            <h5>{currentMascot.name}</h5>
                            <span className="meta small">{new moment().format("MMM Do, hh:mma")}</span>
                          </div>
                        </div>

                        <div className="message-content">
                          <div className="wmde-markdown">
                            <p style={{ color: currentMascot.theme && currentMascot.theme.accentColor }}>
                              This is a reply from the mascot
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="new-message">
                    <textarea className="new-message-field large" placeholder="Ask me anything" />

                    <button
                      className="action"
                      style={{ backgroundColor: currentMascot.theme && currentMascot.theme.brandColor }}
                    >
                      <i className="icon-chevron-right"></i>
                    </button>
                  </div>
                </div>
                {/* END CHAT PREVIEW */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
