import React, { useEffect, useState } from "react";
import "./scss/styles.scss";
import "./icons/icons.css"; // Icon font imported separately from styles
import { Switch, Route, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./hooks/useAuth";
import { PrivateRoute } from "./components/PrivateRoute";
import Loader from "./components/Loader";
import { EmbedChat } from "./embed";
import BobbieImg from "./images/bobbie.png";

// Views
import Home from "./pages/Home";
import Account from "./pages/Account";
import Administrators from "./pages/Administrators";
import ChangePassword from "./pages/ChangePassword";
import { Login } from "./pages/Login";
import { Connect } from "./pages/Connect";
import { Verify } from "./pages/Verify";
import { SignupConfirmed } from "./pages/SignupConfirmed";
import { Logout } from "./pages/Logout";
import { SignUp } from "./pages/Signup";
import { Invitation } from "./pages/Invitation";
import { MissingOrg } from "./pages/MissingOrg";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword";
import { NotFound } from "./pages/NotFound";
import MascotChat from "./pages/MascotChat";
import MascotChatReadOnly from "./pages/MascotChatReadOnly";
import EditMascot from "./pages/EditMascot";
import EditMascotData from "./pages/EditMascotData";
import EditMascotDataComplex from "./pages/EditMascotDataComplex";
import EditMascotDataType from "./pages/EditMascotDataType";
import EditMascotDocument from "./pages/EditMascotDocument";
import EditMascotTheme from "./pages/EditMascotTheme";
import EditMascotSettings from "./pages/EditMascotSettings";
import EditMascotBehaviors from "./pages/EditMascotBehaviors";
import EditMascotQr from "./pages/EditMascotQr";
import EditMascotEmbed from "./pages/EditMascotEmbed";
import Debugger from "./components/Debugger";
import Insights from "./pages/Insights";
import MascotInsights from "./pages/MascotInsights";
import MascotInsightsSessions from "./pages/MascotInsightsSessions";
import MascotInsightsInteractions from "./pages/MascotInsightsInteractions";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(React.useEffect, useLocation),
        tracePropagationTargets: ["localhost", /^https:\/\/staging\.wiseox\.ai/, /^https:\/\/wiseox\.ai/],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
const SentryRoutes = Sentry.withSentryRouting(Route);

export default function App() {
  const { loading: loadingAuth } = useAuth();
  const [pathname, setPathname] = useState("");
  let location = useLocation();

  // Get the current pathname
  useEffect(() => {
    setPathname(location.pathname.split("/")[1]);
  }, [location, pathname]);

  return loadingAuth ? (
    <Loader classNames="dark app-loader" />
  ) : (
    <>
      <SentryRoutes>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/connect" exact component={Connect} />
          <Route path="/signup" component={SignUp} />
          <Route path="/signupConfirmed" component={SignupConfirmed} />
          <Route path="/verify" component={Verify} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/set-password" component={ResetPassword} />
          <Route path="/passwordReset" component={ResetPassword} />
          <Route path="/invitation" component={Invitation} />
          <Route path="/missing-org" component={MissingOrg} />

          <PrivateRoute path="/" exact>
            <Home />
          </PrivateRoute>

          <PrivateRoute path="/account">
            <Account />
          </PrivateRoute>

          <PrivateRoute path="/administrators">
            <Administrators />
          </PrivateRoute>

          <PrivateRoute path="/changePassword">
            <ChangePassword />
          </PrivateRoute>

          <PrivateRoute path="/logout">
            <Logout />
          </PrivateRoute>

          <PrivateRoute path="/edit-mascot/:mascotId">
            <EditMascot />
          </PrivateRoute>

          <PrivateRoute path="/edit-mascot-data-type/:mascotId">
            <EditMascotDataType />
          </PrivateRoute>

          <PrivateRoute path="/edit-mascot-data/:mascotId">
            <EditMascotData />
          </PrivateRoute>

          <PrivateRoute path="/edit-mascot-data-complex/:mascotId">
            <EditMascotDataComplex />
          </PrivateRoute>

          <PrivateRoute path="/edit-mascot-doc/:mascotId/:doucmentId">
            <EditMascotDocument />
          </PrivateRoute>

          <PrivateRoute path="/edit-mascot-theme/:mascotId">
            <EditMascotTheme />
          </PrivateRoute>

          <PrivateRoute path="/edit-mascot-settings/:mascotId">
            <EditMascotSettings />
          </PrivateRoute>

          <PrivateRoute path="/edit-mascot-behaviors/:mascotId">
            <EditMascotBehaviors />
          </PrivateRoute>

          <PrivateRoute path="/edit-mascot-qr/:mascotId">
            <EditMascotQr />
          </PrivateRoute>

          <PrivateRoute path="/edit-mascot-embed/:mascotId">
            <EditMascotEmbed />
          </PrivateRoute>

          <PrivateRoute path="/insights">
            <Insights />
          </PrivateRoute>

          <PrivateRoute path="/mascot/:mascotId/insights/overview">
            <MascotInsights />
          </PrivateRoute>

          <PrivateRoute path="/mascot/:mascotId/insights/sessions">
            <MascotInsightsSessions />
          </PrivateRoute>

          <PrivateRoute path="/mascot/:mascotId/insights/interactions">
            <MascotInsightsInteractions />
          </PrivateRoute>

          <Route path="/mascot/:shareToken/chat">
            <MascotChatReadOnly />
          </Route>

          <Route path="/mascot/:mascotId/session/:sessionId">
            <MascotChat />
          </Route>

          <Route path="/mascot/:mascotId">
            <MascotChat />
          </Route>

          <PrivateRoute path="/*">
            <NotFound />
          </PrivateRoute>
        </Switch>
      </SentryRoutes>
      <ToastContainer position="bottom-right" theme="dark" />

      {process.env.REACT_APP_BOB_ID && (
        <EmbedChat mascotId={process.env.REACT_APP_BOB_ID} label="Ask Bobbie" iconImage={BobbieImg}></EmbedChat>
      )}
      {process.env.REACT_APP_ENABLE_DEBUEGGER === "true" && <Debugger></Debugger>}
    </>
  );
}
