import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useStoreContext, setCurrentMascot } from "../context/store";
import QRCode from "react-qr-code";
import MascotSidebar from "../components/MascotSidebar";
import ColorPicker from "../ui/ColorPicker";

export default function EditMascotQr() {
  const { mascotId } = useParams();
  const { mascots, currentMascot } = useStoreContext();
  const [foregroundColor, setForegroundColor] = useState("#000000");
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const qrRef = useRef();

  const mascotUrl = window.location.origin + "/mascot/" + currentMascot._id + "?source-origin=qr";
  const QRCodeSize = 1024;

  const handleDownload = () => {
    const svg = qrRef.current;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  useEffect(() => {
    setCurrentMascot(mascotId);
  }, [mascots, mascotId]);

  return (
    <div className="mascot-wrapper">
      <MascotSidebar editing sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}></MascotSidebar>

      <div className="edit-mascot">
        <div className="icon-btn open-sidebar" onClick={() => setSidebarOpen(!sidebarOpen)}>
          <i className="icon-menu"></i>
        </div>

        <div className="edit-mascot-header">
          <h1>QR Code</h1>
        </div>

        <div className="content-wrapper full-width col-2">
          <div className="content-col">
            <p>
              Place this QR code wherever you want your users to scan it. You can download it for print or to embed it on another
              website or application.
            </p>
            <p className="meta warning">
              Note: You will need to enable "Public Mascot" in the Edit Mascot tab. We also recommend testing your QR code first
              before downloading.
            </p>

            <h3>Color Options</h3>
            <p>It's best to choose a dark foreground color and light background color.</p>
            <ColorPicker label="Foreground Color" defaultValue={foregroundColor} handleThemeColor={setForegroundColor} />

            <ColorPicker label="Background Color" defaultValue={backgroundColor} handleThemeColor={setBackgroundColor} />
          </div>

          <div className="content-col">
            <div className="preview-window qr-code-window">
              <div className="preview-content">
                <span className="meta">
                  QR Code URL
                  <a href={mascotUrl} target="_blank" rel="noreferrer">
                    {mascotUrl}
                  </a>
                </span>

                <div className="qr-code">
                  <QRCode
                    ref={qrRef}
                    size={QRCodeSize}
                    viewBox={`0 0 ${QRCodeSize} ${QRCodeSize}`}
                    value={mascotUrl}
                    bgColor={backgroundColor}
                    fgColor={foregroundColor}
                  />
                </div>

                <button className="small" onClick={handleDownload}>
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
