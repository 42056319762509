import classNames from "classnames";

// Libs
import moment from "moment";
import { toast } from "react-toastify";

// Context
import { useAuth } from "../hooks/useAuth";
import { useStoreContext } from "../context/store";

// Component
import MascotImage from "./MascotImage";
import Popover from "./Popover";
import MDEditor from "@uiw/react-md-editor";
import { useEffect, useRef } from "react";

export default function MessageChat({
  message,
  mascot,
  newMessageAnimation,
  trainingMode,
  togglePopup,
  i,
  showedPopup,
  addRank,
  showModalFeedback,
  setShowExcerpts,
  selectedMessage,
  chatWrapperEl,
}) {
  const { ownMascot } = useStoreContext();
  const { isAuthenticated } = useAuth();
  const messageEl = useRef();

  useEffect(() => {
    if (selectedMessage && message.role !== "assistant" && new Date(message.timestamp).getTime().toString() === selectedMessage) {
      setTimeout(() => {
        chatWrapperEl.current?.scrollTo({
          top: messageEl.current?.offsetTop,
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }, 400);
    }
    // eslint-disable-next-line
  }, [messageEl.current]);

  const copyToClipboard = async (message) => {
    try {
      await navigator.clipboard.writeText(message.content);
      toast.success("Content copied to clipboard");
    } catch (err) {
      console.error('Failed to copyToClipboard: ', err);
    }
  };

  return (
    <div
      ref={messageEl}
      className={classNames("message", message.role, message.role === "user" && newMessageAnimation && "new-message-animation")}
      style={message.role === "user" ? { borderColor: mascot.theme && mascot.theme.brandColor } : null}
    >
      <div className="message-header">
        {message.role === "assistant" && <MascotImage mascot={mascot}></MascotImage>}

        {message.role === "user" && (
          <div className="user-avatar" style={{ backgroundColor: mascot.theme && mascot.theme.brandColor }}>
            <h3>{message.userName.charAt(0)}</h3>
          </div>
        )}

        <div className="message-header-title">
          <h5>{message.role === "assistant" ? mascot.name : message.userName}</h5>
          <span className="meta small">{new moment(message.timestamp).format("MMM Do, hh:mma")}</span>
        </div>

        {message.role === "assistant" && (
          <>
            {isAuthenticated && ownMascot && trainingMode ? (
              <div className={classNames("message-actions rank-response", message.feedback)}>
                <button className="rank-button xsmall outline" onClick={(e) => togglePopup(i, message)}>
                  {!message.feedback && "Rank"}
                  {message.feedback === "positive" && <i className="icon-thumbs-up"></i>}
                  {message.feedback === "negative" && <i className="icon-thumbs-down"></i>}
                </button>

                {showedPopup[i] && (
                  <Popover close={(e) => togglePopup(i, message)} className="left">
                    <p>
                      <b>Rank This Response</b>
                    </p>

                    <button className="outline pill wide positive" onClick={(e) => addRank(i, message, "positive")}>
                      <i className="icon-thumbs-up"></i>
                      <span>Looks Good</span>
                    </button>

                    <button className="outline pill wide negative" onClick={(e) => showModalFeedback(i, message, "negative")}>
                      <i className="icon-thumbs-down"></i>
                      <span>Not Good</span>
                    </button>
                  </Popover>
                )}
              </div>
            ) : (
              <>
                <div className="message-actions">
                  <div className="icon-btn">
                    <i className="icon-copy" onClick={() => copyToClipboard(message)}></i>
                  </div>
                  
                  {isAuthenticated && ownMascot && (
                    <div className="icon-btn">
                      <i className="icon-file-info" onClick={() => setShowExcerpts(message)}></i>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>

      <div className={classNames("message-content", !mascot.prompt_settings?.inline_actions && "not-hoverable")}>
        <MDEditor.Markdown
          linkTarget="_blank"
          source={message.content}
          data-color-mode="light"
          style={{
            color: mascot.theme && mascot.theme.accentColor,
            backgroundColor: "transparent",
          }}
        />
      </div>
    </div>
  );
}
