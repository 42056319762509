import React, { useEffect, useRef, useCallback } from "react";
import classNames from "classnames";

export default function Popover({ children, close, className }) {
  const popoverRef = useRef();

  const clickListener = useCallback((e) => {
    !(e && popoverRef && popoverRef.current?.contains(e.target)) && close();
  }, [close]);

  useEffect(() => {
    document.addEventListener("mousedown", clickListener);
    return () => {
      document.removeEventListener("mousedown", clickListener);
    }
  }, [clickListener]);

  return (
    <div className={classNames('popover', className)} ref={popoverRef}>
      {children}
    </div>
  );
}
