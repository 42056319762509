import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStoreContext, setCurrentMascot, updateMascot } from "../context/store";
import MascotSidebar from "../components/MascotSidebar";
import CheckBox from "../ui/CheckBox";

import ActionsImage from "../images/actions-image.png";

export default function EditMascotSettings() {
  const { mascotId } = useParams();
  const { currentMascot, mascots } = useStoreContext();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    setCurrentMascot(mascotId);
  }, [mascots, mascotId]);

  const handleReversePromptChange = (e) => {
    updateMascot({
      ...currentMascot,
      prompt_settings: {
        ...currentMascot.prompt_settings,
        reverse_prompt: e.target.checked,
      },
    });
  };

  const handleReversePromptTextChange = (e) => {
    updateMascot({
      ...currentMascot,
      prompt_settings: {
        ...currentMascot.prompt_settings,
        reverse_prompt_text: e.target.value,
      },
    });
  };

  const handleTopicChange = (k, key) => (e) => {
    const newTopics = [...currentMascot.topics];
    const topic = newTopics[k];
    topic[key] = e.target.value;
    updateMascot({ ...currentMascot, topics: newTopics });
  };

  const handleInlineActionsChange = (e) => {
    updateMascot({
      ...currentMascot,
      prompt_settings: {
        ...currentMascot.prompt_settings,
        inline_actions: e.target.checked,
        action_info_enable: e.target.checked,
        action_quiz_enable: e.target.checked,
        action_creative_enable: e.target.checked,
      },
    });
  };

  const handleInlineActionsEnabledChange = (e, type) => {
    currentMascot.prompt_settings["action_" + type + "_enable"] = e.checked;
    updateMascot(currentMascot);
  };

  const handleAddTopic = () => {
    currentMascot.topics.push({});
    updateMascot({
      ...currentMascot,
      topics: currentMascot.topics,
    });
  };

  const handleRemoveTopic = (k) => {
    currentMascot.topics.splice(k, 1);
    updateMascot({ ...currentMascot, topics: currentMascot.topics });
  };

  return (
    <div className="mascot-wrapper">
      <MascotSidebar editing sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}></MascotSidebar>

      <div className="edit-mascot">
        <div className="icon-btn open-sidebar" onClick={() => setSidebarOpen(!sidebarOpen)}>
          <i className="icon-menu"></i>
        </div>

        <div className="edit-mascot-header">
          <h1>Settings</h1>
        </div>

        <div className="content-wrapper full-width col-2">
          <div className="content-col">
            <h3>Prompt Actions</h3>
            <p>
              Prompt Actions are preset prompts that can be selected instead of typing a prompt. Each action requires a button
              label and a description, which should provide enough criteria to inform the mascot's behavior for the prompt.
            </p>
            <img className="actions-img" src={ActionsImage} alt="actions" />

            <button className="small" onClick={() => handleAddTopic()}>
              Add Action
            </button>

            <div className="topics-editor">
              {currentMascot.topics &&
                currentMascot.topics.map((topic, k) => {
                  return (
                    <div className="topic-field" key={k} data-rel={k}>
                      <div className="icon-btn">
                        <i className="icon-close" onClick={() => handleRemoveTopic(k)}></i>
                      </div>

                      <div className="text-field-combo">
                        <input
                          type="text"
                          className="small"
                          placeholder="Button label"
                          value={topic.name}
                          onChange={handleTopicChange(k, "name")}
                        />
                        <input
                          type="text"
                          className="small"
                          placeholder="Prompt description"
                          value={topic.prompt}
                          onChange={handleTopicChange(k, "prompt")}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="content-col">
            <h3>Chat Settings</h3>

            <div className="input-group">
              <div className="switch-wrapper">
                <label>Mascot Greeting</label>
                <span className="meta small">Have the mascot greet the user when a new chat is started</span>
                <div className="switch">
                  <input
                    type="checkbox"
                    checked={(currentMascot.prompt_settings && currentMascot.prompt_settings.reverse_prompt) || ""}
                    onChange={handleReversePromptChange}
                  />
                  <span></span>
                  <span></span>
                </div>
              </div>

              {currentMascot.prompt_settings && currentMascot.prompt_settings.reverse_prompt && (
                <div className="reverse-prompt-text">
                  <input
                    type="text"
                    className="small"
                    placeholder="What would you like the mascot to say?"
                    value={currentMascot.prompt_settings.reverse_prompt_text}
                    onChange={handleReversePromptTextChange}
                  />
                </div>
              )}
            </div>

            <div className="input-group">
              <div className="switch-wrapper">
                <label>Inline Actions</label>
                <span className="meta small">Allows a set of actions that can be requested for each response</span>
                <div className="switch">
                  <input
                    type="checkbox"
                    checked={(currentMascot.prompt_settings && currentMascot.prompt_settings.inline_actions) || ""}
                    onChange={handleInlineActionsChange}
                  />
                  <span></span>
                  <span></span>
                </div>
              </div>

              {currentMascot.prompt_settings && currentMascot.prompt_settings.inline_actions && (
                <div className="inline-action-options">
                  <div className="checkbox-wrapper">
                    <CheckBox
                      checked={(currentMascot.prompt_settings && currentMascot.prompt_settings.action_info_enable) || ""}
                      onChange={(e) => handleInlineActionsEnabledChange(e, "info")}
                    />
                    <label>"Tell me more" - Provide more info on the response</label>
                  </div>
                  <div className="checkbox-wrapper">
                    <CheckBox
                      checked={(currentMascot.prompt_settings && currentMascot.prompt_settings.action_quiz_enable) || ""}
                      onChange={(e) => handleInlineActionsEnabledChange(e, "quiz")}
                    />
                    <label>"Quiz me" - Quiz the user about the response</label>
                  </div>
                  <div className="checkbox-wrapper">
                    <CheckBox
                      checked={(currentMascot.prompt_settings && currentMascot.prompt_settings.action_creative_enable) || ""}
                      onChange={(e) => handleInlineActionsEnabledChange(e, "creative")}
                    />
                    <label>"Be creative" - Write something creative based on the response</label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
