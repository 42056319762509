import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ProvideAuth } from "./hooks/useAuth";
import { SocketProvider } from "./context/ws";
import { StoreProvider } from "./context/store";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <ProvideAuth>
      <StoreProvider>
        <SocketProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SocketProvider>
      </StoreProvider>
    </ProvideAuth>
  </React.StrictMode>,
  document.getElementById("root")
);
