import React, { useEffect, useState } from "react";

// Context
import { setCurrentMascot, useStoreContext, selectSession } from "../context/store";
import { useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

// Components
import MascotSidebarInsights from "../components/MascotSidebarInsights";
import HeaderInsights from "../components/HeaderInsights";
import { client } from "../services/api";
import BarChart from "../components/charts/BarChart";
import TableChart from "../components/charts/TableChart";
import PieChart from "../components/charts/PieChart";
//import DoughnutChart from "../components/charts/DoughnutChart";

export default function MascotInsights() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { intervalTime, setIntervalTime } = useAuth();
  const { mascots } = useStoreContext();
  const { mascotId } = useParams();

  const [dataChart, setDataChart] = useState();
  const [dataUserType, setDataUserType] = useState();
  const [dataUserLocation, setDataUserLocation] = useState();
  const [dataUserBrowser, setDataUserBrowser] = useState();
  const [dataUserOrigin, setDataUserOrigin] = useState();

  useEffect(() => {
    setCurrentMascot(mascotId);
    selectSession();
  }, [mascots, mascotId]);

  useEffect(() => {
    const fetchData = async () => {
      client.getStatsSessionsMascotByDay(mascotId, intervalTime).then((res) => {
        if (res.status === 200) setDataChart(res.data);
      });
      client.getStatsUserTypeByMascot(mascotId, intervalTime).then((res) => {
        if (res.status === 200) setDataUserType(addColorUserType(res.data));
      });
      client.getStatsUserLocationByMascot(mascotId, intervalTime).then((res) => {
        if (res.status === 200) setDataUserLocation(res.data);
      });
      client.getStatsUserOriginByMascot(mascotId, intervalTime).then((res) => {
        if (res.status === 200) setDataUserOrigin(addColorUserOrigin(res.data));
      });
      client.getStatsUserBrowserByMascot(mascotId, intervalTime).then((res) => {
        if (res.status === 200) setDataUserBrowser(addColorUserBrowser(res.data));
      });
    };

    fetchData();
    // eslint-disable-next-line
  }, [intervalTime]);

  const addColorUserType = (data) => {
    for (let item of data) {
      if (item._id === "guest") {
        item.color = "#12D0DB";
      } else if (item._id === "admin") {
        item.color = "#DC0D7D";
      } else if (item._id === "slack") {
        item.color = "#006DD4";
      } else if (item._id === "-1") {
        item.color = "#676767";
      }
    }
    return data;
  };

  const addColorUserOrigin = (data) => {
    for (let item of data) {
      if (item._id === "direct") {
        item.color = "#04D465";
      } else if (item._id === "slack") {
        item.color = "#006DD4";
      } else if (item._id === "embed") {
        item.color = "#D40057";
      } else if (item._id === "qr") {
        item.color = "#C03A0B";
      } else if (item._id === "-1") {
        item.color = "#676767";
      }
    }
    return data;
  };

  const addColorUserBrowser = (data) => {
    for (let item of data) {
      if (item._id === "Google Chrome") {
        item.color = "#007AD4";
      } else if (item._id === "Mozilla Firefox") {
        item.color = "#80BA00";
      } else if (item._id === "Microsoft Edge") {
        item.color = "#80BA00";
      } else if (item._id === "Safari") {
        item.color = "#B836A7";
      } else if (item._id === "Brave") {
        item.color = "#979797";
      } else if (item._id === "Microsoft Internet Explorer") {
        item.color = "#07DC79";
      } else if (item._id === "Opera") {
        item.color = "#979797";
      } else if (item._id === "Samsung Browser") {
        item.color = "#FF6019";
      } else if (item._id === "Electron") {
        item.color = "#979797";
      }
    }
    return data;
  };

  return (
    <div className="mascot-wrapper">
      <MascotSidebarInsights editing sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}></MascotSidebarInsights>

      <div className="mascot-insights-wrapper">
        <HeaderInsights intervalTime={intervalTime} onSetIntervalTime={setIntervalTime}></HeaderInsights>

        <BarChart
          title="Daily User Sessions"
          description="A session is when a user sends a prompt in a chat, and expires after 1 hour of inactivity or page reload."
          className="secondary"
          data={dataChart}
        ></BarChart>

        <div className="charts-wrapper col-2">
          <PieChart title="User Type By Session" data={dataUserType}></PieChart>
          <PieChart title="User Origin By Session" data={dataUserOrigin}></PieChart>
        </div>

        <div className="charts-wrapper col-2">
          <PieChart title="Browsers Used" data={dataUserBrowser}></PieChart>
          {/* <DoughnutChart title={"Bounce Rate"} data={dataBounce}></DoughnutChart> */}
          <TableChart title={"Users By Location"} data={dataUserLocation}></TableChart>
        </div>
      </div>
    </div>
  );
}
