import React, { useState, useEffect } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { toast } from "react-toastify";
import classNames from "classnames";
import MascotImage from "./MascotImage";
import Modal from "./Modal";
import EditNav from "./EditNav";
import Loader from "./Loader";
import { useStoreContext, selectSession, updateMascot, addSession } from "../context/store";
import { client } from "../services/api";
import { publish } from "../services/events";
import Logo from "../images/logo-wiseox.svg";
import { useIntersect } from "../hooks/useIntersect";

export default function MascotSidebar(props) {
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const { currentMascot, ownMascot, sessions, selectedSession, currentOrganization } = useStoreContext();
  const editing = props.editing;
  const onAction = props.onAction;
  const notReady = props.notReady;
  const sidebarOpen = props.sidebarOpen;
  const setSidebarOpen = props.setSidebarOpen;
  const showBadge = props.showBadge || false;
  const setPage = props.setPage;
  const loadMore = props.loadMore;
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [sessionActiveState, setSessionActiveState] = useState(null);
  const [initSession, setInitSession] = useState(false);
  const { sessionId } = useParams();
  const [today, setToday] = useState([]);
  const [last7days, setLast7days] = useState([]);
  const [last30days, setLast30days] = useState([]);
  const [others, setOthers] = useState([]);
  const [showConflictModal, setShowConflictModal] = useState(false);
  const [lazyLoader] = useIntersect({ rootMargin: "0px", threshold: 1.0, onIntersect: handleIntersect });

  function handleIntersect(target) {
    if (target.isIntersecting && loadMore) {
      setPage && setPage((prevPage) => prevPage + 1);
    }
  }

  const saveMascotHandler = async () => {
    if (!currentMascot.name || !currentMascot.description) {
      toast.error("Mascot name and description is required");
      return;
    }

    const res = await client.updateMascot(currentMascot);
    if (!res.ok) {
      if (res.status === 409) {
        setShowConflictModal(true);
        return;
      } else {
        toast.error("Error updating mascot");
        console.error(res.originalError);
      }
    }
    currentMascot.version = res.data.version;
    updateMascot(currentMascot);
    history.push(`/mascot/${currentMascot._id}`);
  };

  const editMascot = async () => {
    const result = await client.getMascot(currentMascot._id);
    if (result.ok) {
      updateMascot(result.data);
      history.push(`/edit-mascot/${currentMascot._id}`);
    }
  };

  const renderActionButtons = () => {
    if (editing) {
      return (
        <button className="done-editing action" onClick={onAction || saveMascotHandler}>
          {props.actionLabel || "Done Editing"}
        </button>
      );
    } else {
      return (
        <div className="mascot-actions">
          {isAuthenticated && ownMascot && currentMascot._id && (
            <span
              className={classNames("mascot-action", props.loading && "disabled")}
              onClick={editMascot}
            >
              <i className="icon-edit"></i>
            </span>
          )}

          {isAuthenticated &&
            ownMascot &&
            currentMascot._id &&
            currentOrganization.features &&
            currentOrganization.features.insights && (
              <span
                className={classNames("mascot-action")}
                onClick={() => history.push(`/mascot/${currentMascot._id}/insights/overview`)}
              >
                <i className="icon-graph"></i>
              </span>
            )}

          {currentMascot.public && (
            <span className={classNames("mascot-action", notReady && "disabled")} onClick={() => setShareModalOpen(true)}>
              <i className="icon-share"></i>
            </span>
          )}

          {currentMascot._id && (
            <span
              className={classNames(
                "mascot-action",
                (props.loading || notReady || (selectedSession && selectedSession._id === "new")) && "disabled"
              )}
              onClick={() => createSession()}
            >
              <i className="icon-new-chat"></i>
            </span>
          )}
        </div>
      );
    }
  };

  useEffect(() => {
    if (sessions.length === 0) return;
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    const sevenDaysAgo = new Date(todayDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const thirtyDaysAgo = new Date(todayDate);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    const todaySessions = [];
    const last7daysSessions = [];
    const last30daysSessions = [];
    const otherSessions = [];

    sessions
      .map((s) => ({ ...s, lastPrompt: new Date(s.lastPrompt) }))
      .sort((s1, s2) => s2.lastPrompt - s1.lastPrompt)
      .forEach((session) => {
        if (session.lastPrompt >= todayDate) {
          todaySessions.push(session);
        } else if (session.lastPrompt >= sevenDaysAgo) {
          last7daysSessions.push(session);
        } else if (session.lastPrompt >= thirtyDaysAgo) {
          last30daysSessions.push(session);
        } else {
          otherSessions.push(session);
        }
      });

    setToday(todaySessions);
    setLast7days(last7daysSessions);
    setLast30days(last30daysSessions);
    setOthers(otherSessions);
  }, [sessions]);

  const SessionsListItem = ({ session }) => {
    return (
      <div className="sessions-list-item" onClick={() => loadSession(session)}>
        <span className="session-title" title={session.title}>
          {session.title}
        </span>
        {sessionActiveState === session._id && <span className="session-active"></span>}
      </div>
    );
  };

  useEffect(() => {
    if (!editing && selectedSession) {
      setSessionActiveState(selectedSession._id);
    }
  }, [editing, selectedSession]);

  const loadSession = (session) => {
    history.push("/mascot/" + currentMascot._id, { shallow: true });
    publish("changeSession", { sessionId: session._id });
    selectSession(session);
  };

  useEffect(() => {
    if (!initSession) {
      for (let s of sessions) {
        if (s._id === sessionId) {
          setInitSession(true);
          setTimeout(() => {
            selectSession(s);
            publish("changeSession", { sessionId: sessionId });
          }, 1000);
        }
      }
    }
  }, [initSession, sessionId, sessions]);

  const createSession = () => {
    publish("changeSession", { sessionId: "new" });
    addSession({ _id: "new", title: "New session", lastPrompt: new Date().toISOString() });
    selectSession({ _id: "new", title: "New session" });
  };

  const refreshMascot = async () => {
    const result = await client.getMascot(currentMascot._id);
    result.ok && updateMascot(result.data);
    setShowConflictModal(false);
  }

  return (
    <div className={classNames("mascot-sidebar", !currentMascot.image && "bg-empty", sidebarOpen && "open")}>
      {/* Close button for mobile */}
      <div className="icon-btn close-sidebar" onClick={() => setSidebarOpen(!sidebarOpen)}>
        <i className="icon-close"></i>
      </div>

      {showBadge &&
        ((!currentOrganization._id && currentMascot._id && currentMascot.hideLogo !== true) ||
          (currentOrganization &&
            currentOrganization._id &&
            (!currentOrganization.features || currentOrganization.features.hideLogo !== true))) && (
          <div className="wiseox-badge">
            <a href="https://wiseox.com" className="logo" target="_blank" rel="noreferrer">
              <img src={Logo} alt="WiseOx Logo" />
            </a>

            <div className="wiseox-badge-links">
              <a href="https://wiseox.com/legal/terms-of-use" className="meta small" target="_blank" rel="noreferrer">
                Terms
              </a>
              <a href="https://wiseox.com/legal/privacy" className="meta small" target="_blank" rel="noreferrer">
                Privacy
              </a>
            </div>
          </div>
        )}

      <div className="mascot-sidebar-content">
        {!editing && isAuthenticated && ownMascot && !props.loading && (
          <Link to="/" className="to-home">
            <i className="icon-chevron-left"></i>
          </Link>
        )}
        {!editing && isAuthenticated && ownMascot && props.loading && (
          <div className="to-home disabled">
            <i className="icon-chevron-left"></i>
          </div>
        )}
        <div className="mascot-header">
          <div className="mascot-image-wrapper">
            {props.loading && (
              <div className="thinking">
                <Loader></Loader>
              </div>
            )}
            <MascotImage mascot={props.mascotInit || currentMascot}></MascotImage>
          </div>

          {currentMascot.name && (
            <div className="mascot-heading">
              <h2>{currentMascot.name}</h2>
              <p>{currentMascot.description}</p>
            </div>
          )}

          {props.mascotInit && (
            <div className="mascot-heading">
              <h2>{props.mascotInit.name}</h2>
              <p>{props.mascotInit.description}</p>
            </div>
          )}
        </div>
        {renderActionButtons()}
        {!props.mascotInit && (
          <>
            {editing ? (
              <EditNav />
            ) : notReady || sessions.length === 0 ? (
              <div className="sessions-container">
                <div className="sessions-list-item empty">
                  <span className={sessions.length === 0 ? "loading" : undefined} style={{ width: "74%" }}></span>
                </div>
                <div className="sessions-list-item empty">
                  <span className={sessions.length === 0 ? "loading" : undefined} style={{ width: "54%" }}></span>
                </div>
                <div className="sessions-list-item empty">
                  <span className={sessions.length === 0 ? "loading" : undefined} style={{ width: "94%" }}></span>
                </div>
                <div className="sessions-list-item empty">
                  <span className={sessions.length === 0 ? "loading" : undefined} style={{ width: "82%" }}></span>
                </div>
              </div>
            ) : (
              <div className="sessions-container">
                {today.length > 0 && <div className="sessions-list-separator">Today</div>}
                {today.map((session, i) => (
                  <SessionsListItem session={session} key={i} />
                ))}
                {last7days.length > 0 && <div className="sessions-list-separator">Last 7 Days</div>}
                {last7days.map((session, i) => (
                  <SessionsListItem session={session} key={i} />
                ))}
                {last30days.length > 0 && <div className="sessions-list-separator">Last 30 Days</div>}
                {last30days.map((session, i) => (
                  <SessionsListItem session={session} key={i} />
                ))}
                {others.length > 0 && <div className="sessions-list-separator">Older</div>}
                {others.map((session, i) => (
                  <SessionsListItem session={session} key={i} />
                ))}
                <div ref={lazyLoader} />
              </div>
            )}
          </>
        )}

        {currentMascot._id && (
          <Modal title="Share Mascot" size="small" isOpen={shareModalOpen} close={() => setShareModalOpen(false)}>
            <div className="input-group">
              <label>Direct link to mascot</label>
              <input type="text" readOnly={true} value={`${window.location.origin}/mascot/${currentMascot._id}`}></input>
            </div>

            <button
              onClick={() => {
                toast.promise(navigator.clipboard.writeText(`${window.location.origin}/mascot/${currentMascot._id}`), {
                  success: "Copied to clipboard",
                  error: "Error copying to clipboard",
                });
              }}
            >
              Copy Link
            </button>
          </Modal>
        )}

        <Modal
          title="Cannot Save Changes"
          size="small"
          isOpen={showConflictModal}
          close={() => setShowConflictModal(false)}
          action={
            <>
              <button onClick={() => setShowConflictModal(false)}>Go Back</button>
              <button onClick={refreshMascot}>Refresh</button>
            </>
          }
        >
          <p>A version of this mascot was saved by another user while you were editing. You can refresh to get the latest version, or go back to review and copy any changes you made.</p>
        </Modal>
      </div>
      <div className="mascot-sidebar-bg-wrapper">
        <div
          className="mascot-sidebar-bg"
          style={
            (currentMascot.image || props.mascotInit) && {
              backgroundImage: `url(${currentMascot.image || props.mascotInit.image})`,
            }
          }
        ></div>
      </div>
    </div>
  );
}
