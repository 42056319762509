import React, { useEffect, useState } from "react";

// Context
import { setCurrentMascot, useStoreContext } from "../context/store";
import { useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

// Components
import MascotSidebarInsights from "../components/MascotSidebarInsights";
import HeaderInsights from "../components/HeaderInsights";
import { client } from "../services/api";
import SingleValueChart from "../components/charts/SingleValueChart";
import PieChart from "../components/charts/PieChart";
import CompareBarChart from "../components/charts/CompareBarChart";
import TableChart from "../components/charts/TableChart";

export default function MascotInsightsInteraction() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { intervalTime, setIntervalTime } = useAuth();
  const { mascots } = useStoreContext();
  const { mascotId } = useParams();

  const [dataTuningMetrics, setDataTuningMetrics] = useState();
  const [dataLinksInteractions, setDataLinksInteractions] = useState();
  const [dataAveragePrompts, setDataAveragePrompts] = useState();
  const [dataActionEvents, setDataActionEvents] = useState();

  useEffect(() => {
    setCurrentMascot(mascotId);
  }, [mascots, mascotId]);

  useEffect(() => {
    const fetchData = async () => {
      client.getStatsSessionsTuningByMascot(mascotId, intervalTime).then((res) => {
        if (res.status === 200) setDataTuningMetrics(res.data);
      });
      client.getStatsSessionsLinksByMascot(mascotId, intervalTime).then((res) => {
        if (res.status === 200) setDataLinksInteractions(res.data);
      });
      client.getStatsSessionsAveragePromptsByMascot(mascotId, intervalTime).then((res) => {
        if (res.status === 200) setDataAveragePrompts(res.data);
      });
      client.getStatsSessionsEventsByMascot(mascotId, intervalTime).then((res) => {
        if (res.status === 200) setDataActionEvents(res.data.count);
      });
    };

    fetchData();
    // eslint-disable-next-line
  }, [intervalTime]);

  return (
    <div className="mascot-wrapper">
      <MascotSidebarInsights editing sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}></MascotSidebarInsights>

      <div className="mascot-insights-wrapper">
        <HeaderInsights intervalTime={intervalTime} onSetIntervalTime={setIntervalTime}></HeaderInsights>

        <div className="charts-wrapper col-2">
          <PieChart title={"Tuning Metrics"} className={"col-2"} data={dataTuningMetrics}></PieChart>
          <TableChart
            className={"col-2"}
            title={"Link Interactions"}
            label={"# of links clicked compared to links provided"}
            unit={"Links Clicked"}
            data={dataLinksInteractions}
          ></TableChart>
        </div>

        <div className="charts-wrapper col-2">
          <CompareBarChart
            title={"Average # of prompts"}
            className={"col-2"}
            data={dataAveragePrompts}
            label="Average # of prompts per session this week versus last week"
          ></CompareBarChart>
          <SingleValueChart
            className={"col-2"}
            title={"Prompt Action Events"}
            label={"Total # of clicks on prompt actions"}
            unit={"Clicks"}
            value={dataActionEvents}
            color="#3AEB7D"
          ></SingleValueChart>
        </div>
      </div>
    </div>
  );
}
