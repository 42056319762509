import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Bottleneck from "bottleneck";
import { client } from "../services/api";
import { useAuth } from "../hooks/useAuth";
import { useStoreContext, setCurrentMascot, updateMascot, addMascotTasks } from "../context/store";
import { servicesClient } from "../services/servicesApi";
import MascotSidebar from "../components/MascotSidebar";
import DragAndDrop from "../ui/DragAndDrop";
import Loader from "../components/Loader";
import complexDataIcon from "../images/data-type-complex.svg";

export default function EditMascotDataComplex() {
  const { currentUser } = useAuth();
  const { mascotId } = useParams();
  const { currentMascot, mascots, uploadInfo } = useStoreContext();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [updatingTask, setUpdatingTask] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const history = useHistory();
  const dndRef = useRef();

  const limiter = useMemo(() => new Bottleneck({ maxConcurrent: 2 }), []);

  useEffect(() => {
    setCurrentMascot(mascotId);
  }, [mascots, mascotId]);

  useEffect(() => {
    const embeddingTask = currentMascot.tasks && currentMascot.tasks.find((task) => task.type === "generateEmbeddingTask");
    if (embeddingTask) {
      setUpdatingTask(embeddingTask);
      setUpdateLoading(true);
    } else {
      setUpdatingTask({});
      setUpdateLoading(false);
    }
  }, [currentMascot]);

  const refreshMascot = useCallback(async () => {
    client.getMascot(mascotId).then((result) => {
      if (result.ok) {
        let newMascot = currentMascot;
        newMascot.data = result.data.data;
        newMascot.tasks = result.data.tasks;
        updateMascot(newMascot);
      }
    });
  }, [mascotId, currentMascot]);

  const uploadFile = useCallback(
    async (file) => {
      if (!currentUser) return;
      const result = await servicesClient.dataUpload(mascotId, file, currentUser._id);
      if (!result.ok) {
        console.log("Upload Error! " + result.originalError);
        toast.error(() => (
          <>
            <b>{file.name}</b>: {String(result.originalError)}.
          </>
        ));
        return;
      }
      if (result.data.error) {
        return;
      }
      toast.success(() => (
        <>
          <b>{result.data.meta.filename}</b> uploaded!
        </>
      ));
      addMascotTasks(currentMascot._id, [result.data]);
    },
    [mascotId, currentMascot, currentUser]
  );

  const fileHandler = useCallback(
    (files) => {
      if (files.length === 0) return;
      const supportedFiles = Array.from(files).filter((file) => {
        if (
          currentMascot.data.uploads.some((u) => u.name === file.name && u.mascot_data_type_at_upload === "complexSpreadsheets")
        ) {
          toast.error(() => (
            <>
              <b>{file.name}</b>: Duplicate files cannot be uploaded.
            </>
          ));
          return false;
        }
        if (uploadInfo.supportedFormats && !uploadInfo.supportedFormats.some((ext) => file.name.toLocaleLowerCase().endsWith(ext))) {
          toast.error(() => (
            <>
              <b>{file.name}</b>: File extension not supported.
            </>
          ));
          return false;
        }
        if (uploadInfo.maxContentLength && uploadInfo.maxContentLength < file.size) {
          const maxSizeMb = (uploadInfo.maxContentLength / (1024 * 1024)).toFixed(2);
          toast.error(() => (
            <>
              <b>{file.name}</b>: File size limit exceeded ({maxSizeMb} MB).
            </>
          ));
          return false;
        }
        return true;
      });
      if (supportedFiles.length === 0) return;
      const id = toast.loading(() => (
        <>
          Uploading <b>{supportedFiles.length > 1 ? `${supportedFiles.length} files` : supportedFiles[0].name}</b>
        </>
      ));
      const uploadPromises = supportedFiles
        .sort(() => 0.5 - Math.random())
        .map((file) => limiter.schedule(() => uploadFile(file)));
      Promise.allSettled(uploadPromises).finally(() => {
        dndRef.current && dndRef.current?.reset();
        toast.dismiss(id);
      });
      limiter.on("done", (i) => {
        const jobs = limiter.jobs().length;
        toast.update(id, {
          render: () => (
            <>
              Uploading{" "}
              <b>
                {jobs} {jobs > 1 ? `files` : `file`}
              </b>
            </>
          ),
        });
      });
    },
    [currentMascot, uploadInfo, limiter, uploadFile]
  );

  const handleDeleteUpload = async (uploadId) => {
    const deleteResult = await servicesClient.dataDelete(mascotId, uploadId);
    await refreshMascot();
    toast.success(() => <>Document <b>{deleteResult.data ? deleteResult.data.name : ""}</b> deleted!</>);
  };

  const handleDeleteTask = async (taskId) => {
    await servicesClient.taskDelete(mascotId, taskId);
    refreshMascot();
  };

  const handleStopTask = async (taskId) => {
    await servicesClient.taskStop(mascotId, taskId);
    if (taskId === updatingTask.id) {
      setUpdatingTask({});
      setUpdateLoading(false);
    }
    refreshMascot();
  };

  const changeDataType = () => {
    currentMascot.dataType = null;
    client.updateMascot({ _id: currentMascot._id, dataType: null });
    updateMascot(currentMascot);
    history.push("/edit-mascot-data-type/" + currentMascot._id);
  };

  return (
    <div className="mascot-wrapper">
      <MascotSidebar editing sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}></MascotSidebar>

      <div className="edit-mascot">
        <div className="icon-btn open-sidebar" onClick={() => setSidebarOpen(!sidebarOpen)}>
          <i className="icon-menu"></i>
        </div>

        <div className="edit-mascot-header">
          <img src={complexDataIcon} alt="Complex Spreadsheets Data Type" />
          <h1>Complex Spreadsheets</h1>
          <button onClick={changeDataType} className="small outline">
            Change Data Type
          </button>
        </div>

        <div className="content-wrapper full-width col-2">
          <div className="content-col">
            <h3>Upload Spreadsheets</h3>

            <p>
              Provide spreadsheets that contain complex formatting. Your sheets may contain multiple columns and rows, numerical
              and textual data, nested tables and merged cells.{" "}
              <a href="https://wiseox.com" target="_blank" rel="noreferrer">
                See example
              </a>
              .
            </p>

            <DragAndDrop ref={dndRef} fileHandler={fileHandler}></DragAndDrop>

            <span className="meta small">
              <i className="icon-lock"></i> Your data is end-to-end encrypted, and never shared. Read our{" "}
              <a href="https://wiseox.com/legal/privacy" target="_blank" rel="noreferrer">
                Privacy Notice
              </a>
              .
            </span>

            <div className="data-list">
              <span className="meta">Previously Uploaded</span>
              <input
                type="text"
                className="small"
                placeholder="Search uploaded documents"
                value={filter}
                onChange={(event) => setFilter(event.target.value)}
              />

              <div className="list">
                <ul>
                  {currentMascot.data &&
                    currentMascot.data.uploads &&
                    currentMascot.data.uploads
                      .filter((doc) => {
                        if (doc.url) return false;
                        if (doc["mascot_data_type_at_upload"] !== "complexSpreadsheets") return false;
                        return doc.name.toLowerCase().includes(filter.toLocaleLowerCase()) || filter === "";
                      })
                      .map((doc) => {
                        return (
                          <li className="list-item" key={doc._id}>
                            <div
                              className="list-item-name"
                              onClick={() => history.push(`/edit-mascot-doc/${currentMascot._id}/${doc._id}`)}
                            >
                              {doc.name}
                            </div>

                            <div className="list-item-actions">
                              {doc.name.endsWith(".xlsx") ||
                              doc.name.endsWith(".ods") ||
                              doc.name.endsWith(".csv") ||
                              doc.name.endsWith(".tsv") ? (
                                <></>
                              ) : (
                                <i
                                  className="icon-edit"
                                  onClick={() => history.push(`/edit-mascot-doc/${currentMascot._id}/${doc._id}#editing`)}
                                ></i>
                              )}

                              {!updateLoading && <i className="icon-remove" onClick={() => handleDeleteUpload(doc._id)}></i>}
                            </div>
                          </li>
                        );
                      })}
                  {currentMascot.tasks &&
                    currentMascot.tasks
                      .filter(
                        (task) => task.type === "parseFileTask" && task.meta.mascot_data_type_at_upload === "complexSpreadsheets"
                      )
                      .map((task) => {
                        return task.status === "failed" ? (
                          <li className={"list-item failed"} key={task.id} title={task.meta.error || ""}>
                            <div className="list-item-name">{task.meta.filename}</div>

                            <div className="list-item-actions">
                              <i className="icon-remove" onClick={() => handleDeleteTask(task.id)}></i>
                            </div>
                          </li>
                        ) : (
                          <li className={"list-item"} key={task.id}>
                            <Loader />
                            <div className="list-item-name">{task.meta.filename}</div>

                            <div className="list-item-actions">
                              <button className="xsmall" onClick={() => handleStopTask(task.id)}>
                                Cancel
                              </button>
                            </div>
                          </li>
                        );
                      })}
                </ul>
              </div>
            </div>
          </div>

          <div className="content-col">
            <div className="mascot-data-alert">
              <i className="icon-warn"></i>
              <p>
                Note: Mascot response times and accuracy can vary depending on the complexity of the sheets, and how much data
                they contain.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
