import React, { useState, useRef } from "react";
import { NavLink, useParams, useHistory } from "react-router-dom";
import Modal from "../components/Modal";
import { removeMascot, useStoreContext } from "../context/store";
import { client } from "../services/api";

export default function EditNav() {
  const { mascotId } = useParams();
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [disabledText, setDisabled] = useState("");
  const archiveInputRef = useRef();
  const history = useHistory();
  const { currentMascot, currentOrganization } = useStoreContext();

  const getMascotDataLink = () => {
    if (!currentOrganization.features || !currentOrganization.features.advancedData) {
      return `/edit-mascot-data/${mascotId}`;
    } else if (!currentMascot.dataType) {
      return `/edit-mascot-data-type/${mascotId}`;
    } else if (currentMascot.dataType === "mixed") {
      return `/edit-mascot-data/${mascotId}`;
    } else if (currentMascot.dataType === "complexSpreadsheets") {
      return `/edit-mascot-data-complex/${mascotId}`;
    }
    return `/edit-mascot-data/${mascotId}`;
  };

  const archiveMascot = async () => {
    if (archiveInputRef.current?.value === "archive") {
      await client.archiveMascot(mascotId);
      removeMascot(mascotId);
      history.push("/");
    }
  };

  return (
    <>
      <nav className="edit-nav">
        <hr className="dark" />

        <NavLink to={`/edit-mascot/${mascotId}`} className="edit-nav-item">
          <i className="icon-edit"></i>
          <span>Edit Mascot</span>
        </NavLink>

        <NavLink to={getMascotDataLink()} className="edit-nav-item">
          <i className="icon-data"></i>
          <span>Manage Data</span>
        </NavLink>

        <NavLink to={`/edit-mascot-behaviors/${mascotId}`} className="edit-nav-item">
          <i className="icon-behavior"></i>
          <span>Behaviors</span>
        </NavLink>

        <NavLink to={`/edit-mascot-settings/${mascotId}`} className="edit-nav-item">
          <i className="icon-settings"></i>
          <span>Settings</span>
        </NavLink>

        <NavLink to={`/edit-mascot-theme/${mascotId}`} className="edit-nav-item">
          <i className="icon-wand"></i>
          <span>Theme</span>
        </NavLink>

        <hr className="dark" />

        <NavLink to={`/edit-mascot-embed/${mascotId}`} className="edit-nav-item">
          <i className="icon-embed"></i>
          <span>Install Mascot</span>
        </NavLink>

        <NavLink to={`/edit-mascot-qr/${mascotId}`} className="edit-nav-item">
          <i className="icon-qr"></i>
          <span>QR Code</span>
        </NavLink>

        <hr className="dark" />

        <div onClick={() => setArchiveModalOpen(true)} className="edit-nav-item">
          <i className="icon-archive"></i>
          <span>Archive Mascot</span>
        </div>
      </nav>

      <Modal
        title="Archive Mascot"
        size="small"
        isOpen={archiveModalOpen}
        close={() => setArchiveModalOpen(false)}
        action={
          <button onClick={archiveMascot} className="danger large wide" disabled={disabledText !== "archive"}>
            Archive
          </button>
        }
      >
        <p>Archiving a mascot will make it publicly unavailable and inaccessible in your account.</p>
        <p>If you want to unarchive this mascot at any point, please contact us.</p>

        <div className="input-group">
          <label>Enter “archive” to submit</label>
          <input
            onKeyDown={(e) => {
              if (e.keyCode === 32) e.preventDefault();
            }}
            onChange={(e) => setDisabled(e.target.value)}
            ref={archiveInputRef}
            type="text"
            defaultValue=""
            placeholder="archive"
          />
        </div>
      </Modal>
    </>
  );
}
