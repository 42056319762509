import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { publish } from "../services/events";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [_socket, setSocket] = useState(null);
  const { clientId, currentUser } = useAuth();

  useEffect(() => {
    if(currentUser && _socket) {
      _socket.emit("setUserId", { userId: currentUser._id });
    }
  }, [currentUser, _socket]);

  useEffect(() => {
    if(!clientId) {
      return;
    }
    const socket = io(process.env.REACT_APP_URL_SERVICES, {
      transports: ['websocket', 'polling'],
      withCredentials: true,
      query: {
        clientId: clientId,
      },
    });
    setSocket(socket);

    function onConnect() {
      console.log("ws connected");
    }

    function onDisconnect(reason, details) {
      console.log("ws disconnected");
      console.log(reason, details);
    }

    function onParseFileTaskEvent(data) {
      publish("parseFileTask", data);
      "error" in data
        ? toast.error(() => (
            <>
              Error parsing <b>{data.meta.filename}</b>!<br />
              <small>{data.value}</small>
            </>
          ))
        : toast.success(() => (
            <>
              Document <b>{data.result.name}</b> parsed!
            </>
          ));
    }

    function onCrawlPageTaskEvent(data) {
      publish("crawlPageTask", data);
      "error" in data
        ? toast.error(() => (
            <>
              Error crawling <b>{data.meta.url}</b>!<br />
              <small>{data.value}</small>
            </>
          ))
        : toast.success(() => (
            <>
              Page <b>{data.result.url}</b> parsed!
            </>
          ));
    }

    function onCrawlPageRefreshTaskEvent(data) {
      publish("crawlPageRefreshTask", data);
      "error" in data
        ? toast.error(() => (
            <>
              Error refreshing <b>{data.meta.url}</b>!<br />
              <small>{data.value}</small>
            </>
          ))
        : toast.success(() => (
            <>
              Page <b>{data.result.url}</b> refreshed!
            </>
          ));
    }

    function onIntegrateFeedbackTaskEvent(data) {
      publish("FeedbackSubmitted", data);
    }

    function onGenerateEmbeddingTaskEvent(data) {
      publish("generateEmbeddingTask", data);
      "error" in data
        ? toast.error(() => (
            <>
              Error updating mascot!
              <br />
              <small>{data.value}</small>
            </>
          ))
        : toast.success(() => (
            <>
              Mascot <b>{data.result.name}</b> updated!
            </>
          ));
    }

    socket.on("connect_error", (err) => {
      console.log("connect_error", err);
    });

    const keepAliveIntervalId = setInterval(() => {
      socket.emit('keep-alive', { timestamp: Date.now() });
    }, 25000);

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("parseFileTask", onParseFileTaskEvent);
    socket.on("crawlPageTask", onCrawlPageTaskEvent);
    socket.on("crawlPageRefreshTask", onCrawlPageRefreshTaskEvent);
    socket.on("integrateFeedbackTask", onIntegrateFeedbackTaskEvent);
    socket.on("generateEmbeddingTask", onGenerateEmbeddingTaskEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("parseFileTask", onParseFileTaskEvent);
      socket.off("crawlPageTask", onCrawlPageTaskEvent);
      socket.off("crawlPageRefreshTask", onCrawlPageRefreshTaskEvent);
      socket.off("integrateFeedbackTask", onIntegrateFeedbackTaskEvent);
      socket.off("generateEmbeddingTask", onGenerateEmbeddingTaskEvent);
      clearInterval(keepAliveIntervalId);
    };
  }, [clientId]);

  return <SocketContext.Provider value={{ socket: _socket }}>{children}</SocketContext.Provider>;
};

export const useSocket = () => {
  return useContext(SocketContext);
};
