import { React, useEffect, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { client } from "../services/api";

import BgImg from "../images/sign-in-bg.png";
import Logo from "../images/logo-wiseox.svg";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export function Verify() {
  const query = useQuery();
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    client.verifyAccount(query.get("userId"), query.get("token")).then(res => {
      if (!res.ok) {
        setError(res.data?.message);
      } else if (res.data?.status) {
        setStatus(res.data?.status);
      }
    });
  }, [query]);

  return (
    <div className="auth-wrapper" style={{ backgroundImage: `url(${BgImg})` }}>
      <div className="logo-wrapper">
        <img className="logo" src={Logo} alt="WiseOx Logo" />
      </div>

      <div className="signup-form">
        {error ?
          <>
            <h1>Error</h1>
            <p>{error}</p>
          </> :
          <>
            <h1>Account Verified</h1>
            <p>{status || 'Thank you for verifying your account.'}</p>
          </>
        }

        <NavLink className="button positive large" to={`/login?email=${encodeURIComponent(query.get("email"))}`}>
          Go to Sign In
        </NavLink>
      </div>
    </div>
  );
}
