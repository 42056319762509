import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { JsonToTable } from "react-json-to-table";
import { client } from "../services/api";
import { useStoreContext, setCurrentMascot, updateMascot } from "../context/store";
import { servicesClient } from "../services/servicesApi";
import MascotSidebar from "../components/MascotSidebar";
import classNames from "classnames";
import Loader from "../components/Loader";

export default function EditMascotDocument() {
  const { mascotId, doucmentId } = useParams();
  const { currentMascot, currentOrganization, mascots } = useStoreContext();
  const location = useLocation();
  const history = useHistory();
  const [documentName, setDocumentName] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [_document, setDocument] = useState("");
  const [editingDoc, setEditingDoc] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [jsonDoc, setJsonDoc] = useState(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    setCurrentMascot(mascotId);
  }, [mascots, mascotId]);

  useEffect(() => {
    client.getMascotUploads(mascotId).then((result) => {
      if (result.ok && result.data.length > 0) {
        let doc = result.data.find((u) => u._id === doucmentId);

        if (doc && doc.url) {
          setIsEditable(false);
          setDocumentName(doc.url);
        } else if (doc) {
          setDocumentName(doc.name);
          if (doc.name.endsWith(".xlsx") || doc.name.endsWith(".ods") || doc.name.endsWith(".csv") || doc.name.endsWith(".tsv")) {
            setIsEditable(false);
          } else {
            setIsEditable(true);
          }
        }
      }
    });
    servicesClient.dataGet(mascotId, doucmentId).then((result) => {
      if (result.ok && result.data) {
        if (typeof result.data === 'string' || result.data instanceof String) {
          setDocument(result.data.toString().replace(/^(\r\n|\n|\r)+/, ""));
        } else {
          try {
            setDocument(JSON.stringify(result.data).replace(/^(\r\n|\n|\r)+/, ""));
          } catch(e) {}
        }
      }
    });
  }, [mascotId, doucmentId]);

  const handleEditState = useCallback(
    async (state) => {
      if (!state && _document !== canvasRef.current?.innerText) {
        setUpdating(true);
        const documentResult = await servicesClient.dataUpdate(mascotId, doucmentId, canvasRef.current?.innerText);
        if (typeof documentResult.data === 'string' || documentResult.data instanceof String) {
          setDocument(documentResult.data);
        } else {
          try {
            setDocument(JSON.stringify(documentResult.data));
          } catch(e) {}
        }
        await client.getMascot(mascotId).then((result) => {
          if (result.ok) {
            updateMascot(result.data);
          }
        });
        setUpdating(false);
      }

      setEditingDoc(state);

      if (editingDoc) {
        canvasRef.current?.blur();
      } else {
        canvasRef.current?.focus({
          preventScroll: true,
        });
      }
    },
    [_document, doucmentId, editingDoc, mascotId]
  );

  useEffect(() => {
    if (_document && !editingDoc && location.hash === "#editing") {
      handleEditState(true);
    }
  }, [location.hash, handleEditState, editingDoc, _document]);

  useEffect(() => {
    if (!isEditable && _document) {
      try {
        setJsonDoc(JSON.parse('['+_document.split('\n').join(',').slice(0, -1)+']'));
      } catch(e) { }
    }
  }, [isEditable, _document]);

  const getMascotDataLink = () => {
    if (!currentOrganization.features || !currentOrganization.features.advancedData) {
      return `/edit-mascot-data/${mascotId}`;
    } else if (!currentMascot.dataType) {
      return `/edit-mascot-data-type/${mascotId}`;
    } else if (currentMascot.dataType === "mixed") {
      return `/edit-mascot-data/${mascotId}`;
    } else if (currentMascot.dataType === "complexSpreadsheets") {
      return `/edit-mascot-data-complex/${mascotId}`;
    }
    return `/edit-mascot-data/${mascotId}`;
  };
  
  return (
    <div className="mascot-wrapper">
      <MascotSidebar 
        editing
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      ></MascotSidebar>

      <div className="edit-mascot edit-doc">
        <div 
          className="icon-btn open-sidebar"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <i className="icon-menu"></i>
        </div>

        <div className="edit-doc-header">
          <div className="edit-doc-title">
            <h4>{documentName}</h4>
            <span className="meta small">This document was converted to plain text.</span>
          </div>

          <div className={classNames("edit-doc-label", editingDoc && "editing")}>Editing Document</div>

          <div className="edit-doc-actions">
            {isEditable && (
              <>
                {editingDoc ? (
                  <button 
                    disabled={updating}
                    className="small" 
                    onClick={() => handleEditState(false)}
                  >
                    Save Changes
                    {updating && <Loader />}
                  </button>
                ) : (
                  <i className="icon-edit" onClick={() => handleEditState(true)}></i>
                )}
              </>
            )}

            <i className="icon-close" onClick={() => history.push(getMascotDataLink())}></i>
          </div>
        </div>

        {!_document && <Loader classNames="large" />}

        <div className="content-wrapper">
          {jsonDoc ?
            <JsonToTable json={jsonDoc} /> :
            <>
              {/* DOCUMENT CANVAS */}
              <div className={classNames('doc-canvas', editingDoc && 'editing')}>
                <p
                  tabIndex="0"
                  id="AUA"
                  ref={canvasRef}
                  contentEditable={editingDoc}
                  suppressContentEditableWarning={true}
                  className="doc-canvas-text"
                >
                  {_document}
                </p>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
}
