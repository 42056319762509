import React from "react";

export default function CheckBox(props) {
  const ref = React.useRef();

  return (
    <div className="checkbox">
      <input type="checkbox" {...props} ref={ref} />

      <span
        onClick={() => {
          ref.current.checked = !ref.current?.checked;
          props.onChange(ref.current);
        }}
      >
        <i className="icon-checkmark"></i>
      </span>
    </div>
  );
}
